import { FooterSecondaryDiv, FooterSecondaryText, FlexDiv, CornucopiaIcon, FooterLongText, FooterTextOne, FooterTextTwo, FooterTextBtn } from "../assets/styles/styles";
import XIcon from '../assets/images/XLogo.svg';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import cornucopia_landing_icon from "../assets/images/logo.jpeg";
import { CONTACT_US, QUICK_LINKS, COPYRIGHTS } from "../constants/text";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { Container } from "@mui/material";

const QUICK_LINKS_DATA = [
    {
        id: '1',
        Name: 'Home',
        margin: '2rem',
        navigation_url: '/'
    },
    {
        id: '2',
        Name: 'Market',
        margin: '1rem',
        navigation_url: '/products'
    },
    {
        id: '3',
        Name: 'Sellers',
        margin: '1rem',
        navigation_url: '/sellers'
    },

];

const CONTACT_US_DATA = [
    {
        id: '1',
        Name: '400 Warren St Blacksburg, Virginia. 24060',
        margin: '2rem',
        width: '10rem'
    },
    {
        id: '2',
        Name: 'cornucopia@gmail.com',
        margin: '1rem',
        width: '',
        email: 'mailto:cornucopia@gmail.com'
    },
]

const Footer = () => {
    const isDesktop = useMediaQuery({ minWidth: 1200 });
    const isLaptop = useMediaQuery({ minWidth: 500, maxWidth: 750 });
    const isMobile = useMediaQuery({ maxWidth: 500 });
    const navigate = useNavigate();

    const handleNavigation = (navigation_url) => {
        navigate(navigation_url);
    };

    const handleSocialClick = (url) => {
        window.open(url, '_blank');
    };

    return (
        <Container maxWidth={false} sx={{ maxWidth: '1800px' }} disableGutters>
            <footer style={{ zIndex: "50", position: "relative", backgroundColor: "#fff" }}>
                {!(isLaptop || isMobile) &&
                    <FlexDiv style={{ width: '100%', justifyContent: 'space-between' }}>

                        <div>
                            <CornucopiaIcon
                                src={cornucopia_landing_icon}
                                alt=""
                                style={{ cursor: "pointer", margin: '4rem 0rem 0rem 4rem' }}
                            />
                            <FooterLongText style={{ width: isDesktop ? "27rem" : "20rem", marginLeft: '4.6rem', marginBottom: '4.87rem', marginTop: "20px" }}>
                                Level the playing field – we bring cutting-edge AI to the heart of
                                small and medium businesses. Our marketplace makes AI accessible and easy to
                                implement.
                            </FooterLongText>
                        </div>
                        <FlexDiv style={{ marginRight: isDesktop ? '5rem' : '3rem', gap: isDesktop ? "7rem" : '3.5rem', marginTop: (!isMobile && !isLaptop) ? '1rem' : '' }}>
                            <div>
                                <FooterTextOne style={{ marginTop: '4rem', fontWeight: 'bold' }}>{QUICK_LINKS}</FooterTextOne>
                                {QUICK_LINKS_DATA.map((data) => (
                                    <FooterTextBtn key={data.id} onClick={() => { handleNavigation(data.navigation_url) }}><FooterTextTwo style={{ marginTop: data.margin }}>{data.Name}</FooterTextTwo></FooterTextBtn>
                                ))}
                            </div>
                            <div>
                                <FooterTextOne style={{ marginTop: '4rem', fontWeight: 'bold' }}>{CONTACT_US}</FooterTextOne>
                                {CONTACT_US_DATA.map((data) => (
                                    <FooterTextOne key={data.id} style={{ marginTop: data.margin, width: data.width }}>
                                        {data.email ? (
                                            <a style={{ color: '#000', textDecoration: 'none' }} href={data.email}>{data.Name}</a> // Wrap email with anchor tag
                                        ) : (
                                            data.Name
                                        )}
                                    </FooterTextOne>
                                ))}
                            </div>
                        </FlexDiv>
                    </FlexDiv>
                }
                {(isLaptop || isMobile) &&
                    <div style={{ width: '100%' }}>
                        <div style={{ textAlign: 'center' }}>
                            <CornucopiaIcon
                                src={cornucopia_landing_icon}
                                alt=""
                                style={{ cursor: "pointer", margin: '2rem 0rem 0rem 0rem' }}

                            />
                        </div>
                        <FlexDiv style={{ marginLeft: isMobile ? '0.5rem' : '3rem', marginRight: isMobile ? '0.5rem' : '3rem', justifyContent: 'space-between', marginBottom: '2rem' }}>
                            <div>
                                <FooterTextOne style={{ marginTop: '4rem' }}>{QUICK_LINKS}</FooterTextOne>
                                {QUICK_LINKS_DATA.map((data) => (
                                    <FooterTextBtn key={data.id} onClick={() => handleNavigation(data.navigation_url)}><FooterTextTwo style={{ marginTop: data.margin }}>{data.Name}</FooterTextTwo></FooterTextBtn>
                                ))}
                            </div>
                            <div>
                                <FooterTextOne style={{ marginTop: '4rem' }}>{CONTACT_US}</FooterTextOne>
                                {CONTACT_US_DATA.map((data) => (
                                    <FooterTextOne key={data.id} style={{ marginTop: data.margin, width: data.width }}>
                                        {data.email ? (
                                            <a style={{ color: '#000', textDecoration: 'none' }} href={data.email}>{data.Name}</a> // Wrap email with anchor tag
                                        ) : (
                                            data.Name
                                        )}
                                    </FooterTextOne>
                                ))}
                            </div>
                        </FlexDiv>
                    </div>
                }
                <Container maxWidth={false} sx={{ maxWidth: '1800px' }} disableGutters>
                    <FooterSecondaryDiv style={{ justifyContent: 'space-between' }}>
                        <FooterSecondaryText style={{ padding: isMobile ? '1.44rem 0rem 0rem 1rem' : isLaptop ? '1.44rem 0rem 0rem 2.9rem' : '1.44rem 0rem 0rem 4.19rem' }}>{COPYRIGHTS}</FooterSecondaryText>
                        <FlexDiv style={{ marginRight: isMobile ? '1rem' : '3rem', gap: '0.4rem' }}>
                            <button style={{ display: 'contents' }} onClick={() => handleSocialClick('https://twitter.com/CornucopiaAI?s=20')}>
                                <img alt="" src={XIcon} style={{ marginTop: '1.2rem', color: 'white', width: '24px', height: '24px' }} />
                            </button>
                            <button style={{ display: 'contents' }} onClick={() => handleSocialClick('https://www.instagram.com/cornucopia_ai/')}>
                                <InstagramIcon style={{ marginTop: '1.2rem', color: 'white' }} />
                            </button>
                            <button style={{ display: 'contents' }} onClick={() => handleSocialClick('https://www.linkedin.com/company/cornucopia-ai/')}>
                                <LinkedInIcon style={{ marginTop: '1.2rem', color: 'white' }} />
                            </button>
                        </FlexDiv>
                    </FooterSecondaryDiv>
                </Container>
            </footer>
        </Container>

    )
}
export default Footer;