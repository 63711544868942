import { FlexDiv } from "../../assets/styles/styles";
import { useMediaQuery } from "react-responsive";
import PhotoUploadBox from "./PhotoUploadBox";
import { useEffect } from 'react'
import styled from "@emotion/styled";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Typography, TextField, Grid, Switch } from "@mui/material";
import { Textarea } from "@mui/joy";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { useState } from "react";
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from "@mui/material";
import Caution from "../../assets/images/caution.svg";
import InputAdornment from '@mui/material/InputAdornment';
import VideoUploadBox from "./VideoUploadBox";
import { useNavigate } from 'react-router-dom';
import UserProfileSeller from './UserProfileSeller'
import CircularProgress from '@mui/material/CircularProgress';
import { Snackbar } from "@mui/material";
import MuiAlert from '@mui/material/Alert';
import { useParams, useLocation } from 'react-router-dom';
import { ADD_NEW_PRODUCT, BACK_TO_PRODUCT_LIST, CAN_WE_USE, DISCARD, ECOMMERCE_DASHBOARD, HTTPS, PRODUCT_CATEG, PRODUCT_URL, PRICE, PRODUCT_DEMO, PRODUCT_DESCRIPTION, PRODUCT_DESC_LENGTH, PRODUCT_IMAGES, PRODUCT_NAME, SELECT_CATEG } from "../../constants/text";
import Tooltip from '@mui/material/Tooltip';
import { getProductDetails, addProduct, updateProduct } from "../../Services/Products";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import Modal from "react-modal";
import CardIcon from "../../assets/images/card_icon.svg"
import TermsConditionsModal from "../ProductDetailsBuyer/TermsAndCondition";
import { createProductPaymentApi } from "../../Services/Payment";
import { Helmet } from "react-helmet";
import ZipUploadBox from "./ZipUploadBox";
import { useNotification } from "../../Context/NotificationContext";
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import DoneIcon from '@mui/icons-material/Done';
import ListItemIcon from '@mui/material/ListItemIcon';
import axios from "axios";
import { IP_ADD } from "../../constants/api";
import ProductAddedModal from "./ProductAddedModal";

const TagDiv = styled.div`
    display: flex;
    height: 2.125rem;
    border-radius: 0.3125rem;
    background: #F9F9F9;
    color: #8F8F8F;
    font-family: Poppins;
    font-size: 0.8125rem;
    font-weight: 500;
`;

const BackDiv = styled.div`
    width: 3.0625rem;
    height: 3.0625rem;
    border-radius: 0.375rem;
    border: 1px solid #D4D4D4;
    background: #FFF;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const ProductDescriptionLength = styled.div`
    color: #888;
    font-family: Poppins;
    font-size: 0.75rem;
    font-style: italic;
    font-weight: 500;
    display: flex;
    justify-content: flex-end;
    margin-top: 0.5rem;
`;

const DiscardBtn = styled.div`
    width: 7.0625rem;
    height: 2.8125rem;
    border-radius: 0.4375rem;
    border: 1px solid #E2E2E2;
    background: #EDEDED;
    color: #8F8F8F;
    font-family: Poppins;
    font-size: 0.9375rem;
    font-weight: 500;
    text-align: center;
    padding-top:0.6rem;
    &:hover {
        color: #EDEDED;
        background: #8F8F8F;
    }          
`;

const PublishProduct = styled.div`
    width: 11.75rem;
    height: 2.8125rem;
    padding-top:0.6rem;
    border-radius: 0.4375rem;
    background: #3F0065;
    color: #FFF;
    text-align: center;
    font-family: Poppins;
    font-size: 0.9375rem;
    font-weight: 500;
    &:hover {
        border: 1px solid #3F0065;
        color: #3F0065;
        background: #FFF;
    }          
`;

const placeholderStyles = {
    color: '#747474',
    fontFamily: 'Poppins',
    fontSize: '0.9375rem',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
};


const BackToProduct = {
    color: "#888",
    fontFamily: "Poppins",
    fontSize: "0.8125rem",
    fontWeight: 500,
    marginTop: '0.1rem'
};

const AddProduct = {
    color: "#6D0085",
    fontFamily: "Poppins",
    fontSize: "1.25rem",
    fontWeight: 'bold'
};

const ProductText = {
    color: "#888",
    fontFamily: "Poppins",
    fontSize: "0.875rem",
    fontWeight: 500,
    marginBottom: '0.5rem'
};

const names = [
    'Business',
    'Learning',
    'Analytics',
    'NLP',
    'Computer Vision',
    'Speech Processing',
    'Audio Processing',
    'E-Commerce',
    'IoT',
    'Gaming',
    'Robotics',
    'Augmented Reality',
    'Virtual Reality',
    'Education Technology',
    'Predictive Maintenance',
    'Recommender Systems',

];

const PricePrefix = {
    width: '3.1875rem',
    height: '3.5rem',
    borderRadius: '0.3rem',
    border: '1px solid #D3D3D3',
    background: '#D3D3D3',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
};

const DollarSign = {
    color: '#565656',
    fontFamily: 'Poppins',
    fontSize: '1.25rem',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
const enterCard = {
    color: 'var(--gray-500, #667085)',
    fontFamily: 'Poppins',
    fontSize: '0.875rem',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '1.25rem',
    marginTop: '0.5rem',
    marginBottom: '2.3rem'
};
const featureAddBtn = {
    width: '2.5rem',
    height: '2rem',
    flexShrink: 0,
    borderRadius: '0.1875rem',
    background: '#3F0065',
    color: '#fff',
    textTransform: 'none',
    fontFamily: 'Poppins',
    fontSize: '0.875rem',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
    border: 'none',
};
const AddNewProduct = () => {
    const isLargeLaptop = useMediaQuery({ minWidth: 720 });
    const isLaptop = useMediaQuery({ minWidth: 620 });
    const isDesktop = useMediaQuery({ minWidth: 1200 });
    const isMobile = useMediaQuery({ minWidth: 470 });
    const { paymentFailSeller, setPaymentFailSeller, setPaymentSuccess, paymentSuccess } = useNotification();
    const [personName, setPersonName] = useState([]);
    const [isTextFieldFocused, setTextFieldFocused] = useState(false);
    const [isProductTextareaFocused, setProductTextareaFocused] = useState(false);
    const [isFeaturesFocused, setIsFeaturesFocused] = useState(false);
    const [isPerksFocused, setIsPerksFocused] = useState(false);
    const [isUrlFocused, setUrlFocused] = useState(false);
    const [isPriceFocused, setPriceFocused] = useState(false);
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const [selectedVideo, setSelectedVideo] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [uploadedImages, setUploadedImages] = useState([]);
    const [productName, setProductName] = useState('');
    const [productDescription, setProductDescription] = useState('');
    const [productURL, setProductURL] = useState('');
    const [productPrice, setProductPrice] = useState('');
    const [dataSubmission, setDataSubmission] = useState(false);
    const [isShareable, setIsShareable] = useState(false);
    const [open, setOpen] = useState(false);
    const [snackbarMsg, setSnackbarMsg] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const isEdit = searchParams.get('edit');
    const [loading, setLoading] = useState(true);
    const [productDataTemp, setProductDataTemp] = useState([]);
    const [isDataChanged, setIsDataChanged] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [paymentDone, setPaymentDone] = useState(false);
    const [isTermsModalOpen, setIsTermsModalOpen] = useState(false);
    const [featureInput, setFeatureInput] = useState('');
    const [features, setFeatures] = useState([]);
    const [perksInput, setPerksInput] = useState('');
    const [perks, setPerks] = useState([]);
    const [productModalOpen, setProductModalOpen] = useState(false);
    const [productId, setProductId] = useState('');
    const { type } = useParams();
    const isSmallMobile = useMediaQuery({ maxWidth: 450 });
    const user = JSON.parse(localStorage.getItem('Cornucopia-user'));
    const userId = user.response.id
    const stripe = useStripe();
    const elements = useElements();

    const handleProductModalClose = () => {
        setProductModalOpen(false);
        handleNavigate();
    };

    const handleTermsModalClose = () => {
        setDataSubmission(false);
        setIsTermsModalOpen(false);
    };
    const handleTermsModalOpen = async (e) => {
        setIsTermsModalOpen(true);
    };
    const handleModalClose = ({ submission = false } = {}) => {
        setLoading(false);
        setDataSubmission(submission);
        setIsModalOpen(false);
    };

    const handleModalOpen = () => {
        handleTermsModalClose();
        setIsModalOpen(true);
        setDataSubmission(true);
    }
    const handleFeatureInputChange = (event) => {
        setFeatureInput(event.target.value);
    };

    const handleAddFeature = () => {
        if (featureInput.trim() !== '') {
            setFeatures([...features, featureInput]);
            setFeatureInput('');
        }
    };
    const removeFeature = (index) => {
        const updatedFeatures = [...features];
        updatedFeatures.splice(index, 1);
        setFeatures(updatedFeatures);
    };

    const handlePerksInputChange = (event) => {
        setPerksInput(event.target.value);
    };

    const handleAddPerks = () => {
        if (perksInput.trim() !== '') {
            setPerks([...perks, perksInput]);
            setPerksInput('');
        }
    };
    const removePerk = (index) => {
        const updatedPerks = [...perks];
        updatedPerks.splice(index, 1);
        setPerks(updatedPerks);
    };

    const handlePayment = async () => {
        setLoading(true);
        const cardElement = elements.getElement(CardElement);
        const { paymentMethod, error } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
        });

        if (error) {
            console.error('Error creating payment method:', error);
            setSnackbarMsg(error.message);
            setSnackbarSeverity('error');
            setOpen(true);
            setLoading(false);
            return;
        } else {
            const apiResponse = await createProductPaymentApi({
                userId,
                amount: '10000', // Pass the appropriate amount
                paymentMethod,
            });
            setLoading(false);

            if (apiResponse.success && apiResponse.data.message != 'Something went wrong') {
                handleModalClose({ submission: true });
                setSnackbarMsg('Payment Successful');
                setSnackbarSeverity('success');
                setPaymentDone(true);
                setOpen(true);
                setTimeout(() => {
                    handleProductAddition();
                }, 3000)
            } else {
                console.error('Error in Payment:', apiResponse.error);
                // setSnackbarMsg(apiResponse.error);
                // setSnackbarSeverity('error');
                // setOpen(true);
            }
        }
    };
    const fetchData = async (productId) => {
        try {
            const responseData = await getProductDetails(productId);
            setProductName(responseData.response.name);
            setProductDescription(responseData.response.description);
            setProductURL(responseData.response.url);
            setProductPrice(responseData.response.price);
            setPersonName(responseData.response.category || []);
            setUploadedImages(responseData.response.images || []);
            setSelectedVideo(responseData.response.demoVideo == 'null' ? null : responseData.response.demoVideo);
            setProductDataTemp([responseData.response.name, responseData.response.description, responseData.response.url, responseData.response.price, responseData.response.category || [], responseData.response.images || [], responseData.response.demoVideo == 'null' ? null : responseData.response.demoVideo, responseData.response.productFiles[0] || [], responseData.response.features || [], responseData.response.perks || []]);
            setSelectedFile(responseData.response.productFiles == 'null' ? null : responseData.response.productFiles[0]);
            setFeatures(responseData.response.features || [])
            setPerks(responseData.response.perks || [])
        } catch (error) {
            console.error('Error Retrieving product:', error);
        }
    };


    const [errorMessages, setErrorMessages] = useState({
        productName: "",
        productDescription: "",
        productURL: "",
        productPrice: "",
        selectedCategories: "",
        uploadedImages: "",

    });

    const handleSnackBarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
        setSnackbarMsg('');
    };

    const handleOpen = () => {
        setDropdownOpen(true);
    };

    const handleClose = () => {
        setDropdownOpen(false);
    };

    const handlePriceFocus = () => {
        setPriceFocused(true);
    };

    const handlePriceBlur = () => {
        setPriceFocused(false);
    };


    const handleUrlFocus = () => {
        setUrlFocused(true);
    };

    const handleUrlBlur = () => {
        setUrlFocused(false);
    };

    const handleProductTextareaFocus = () => {
        setProductTextareaFocused(true);
    };

    const handleProductTextareaBlur = () => {
        setProductTextareaFocused(false);
    };

    const handleNameTextFieldFocus = () => {
        setTextFieldFocused(true);
    };

    const handleNameTextFieldBlur = () => {
        setTextFieldFocused(false);
    };

    const handleFeaturesFocused = () => {
        setIsFeaturesFocused(true);
    };

    const handleFeaturesBlur = () => {
        setIsFeaturesFocused(false);
    };

    const handlePerksFocused = () => {
        setIsPerksFocused(true);
    };

    const handlePerksBlur = () => {
        setIsPerksFocused(false);
    };

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setPersonName(value
        );
    };

    const navigate = useNavigate();

    const handleNavigate = () => {
        navigate('/seller/products');
    };

    const handleProductAddition = async () => {
        try {
            setDataSubmission(true);
            setSnackbarMsg('Adding Product');
            setSnackbarSeverity('info');
            setOpen(true);
            const formData = new FormData();
            formData.append('name', productName);
            formData.append('description', productDescription);
            formData.append('url', productURL);
            personName.forEach((category) => {
                formData.append('category', category);
            });
            formData.append('price', productPrice);
            features.forEach((feature) => {
                formData.append('features', feature);
            });
            perks.forEach((perk) => {
                formData.append('perks', perk);
            });
            uploadedImages.forEach((image) => {
                formData.append('images', image);
            });
            formData.append('shareable', isShareable);

            // Append type-specific data based on the 'type' parameter
            if (type === 'website') {
                formData.append('type', 'web');
                if (selectedVideo) {
                    formData.append('demoVideo', `https://${process.env.REACT_APP_AWS_BUCKET_NAME}.s3.amazonaws.com/${encodeURIComponent(selectedVideo.name)}`);
                }
            } else if (type === 'desktop') {
                formData.append('type', 'desktop');
                if (selectedVideo) {
                    formData.append('demoVideo', `https://${process.env.REACT_APP_AWS_BUCKET_NAME}.s3.amazonaws.com/${encodeURIComponent(selectedVideo.name)}`);
                }
                if (selectedFile) {
                    formData.append('productFiles', `https://${process.env.REACT_APP_AWS_BUCKET_NAME}.s3.amazonaws.com/${encodeURIComponent(selectedFile.name)}`);
                }
            }

            const apiUrl = `${IP_ADD}/api/products/sign-url`;

            // Prepare payload
            const payload = {
                files: [],
            };

            if (selectedVideo) {
                payload.files.push({
                    fileName: selectedVideo.name,
                    fileType: selectedVideo.type,
                });
            }

            if (selectedFile) {
                payload.files.push({
                    fileName: selectedFile.name,
                    fileType: selectedFile.type,
                });
            }
            // Get pre-signed URLs
            const response = await axios.post(apiUrl, payload, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            const signedUrls = response.data;

            // Upload files to S3
            const uploadPromises = signedUrls.map(async (signedUrl, index) => {
                let file;
                if (index === 0 && selectedVideo) {
                    file = selectedVideo;
                } else if (index === 0 && !selectedVideo) {
                    file = selectedFile;
                }
                if (index === 1) {
                    file = selectedFile;
                }
                //Upload the file to the signedUrl
                const res = await axios.put(signedUrl.url, file, {
                    headers: {
                        'Content-Type': file.fileType,
                    },
                });
            });

            await Promise.all(uploadPromises);

            const success = await addProduct(formData, setProductId);
            setDataSubmission(false);
            setSnackbarMsg("Product Added Successfully");
            setSnackbarSeverity("success");
            setOpen(true);
            if (type === 'website') {
                setProductModalOpen(true);
            }
            else {
                setTimeout(() => {
                    handleNavigate();
                }, 3000);
            }
        } catch (error) {
            setSnackbarMsg(error.message ? error.message : "Product Creation Failed");
            setSnackbarSeverity("error");
            setOpen(true);
            setDataSubmission(false);
            console.error('Product creation error:', error);
        }
    };

    const handleProductUpdate = async () => {
        try {
            const formData = new FormData();

            // Check each field and update the FormData if the value has changed
            if (productName !== productDataTemp[0]) {
                formData.append('name', productName);
            }

            if (productDescription !== productDataTemp[1]) {
                formData.append('description', productDescription);
            }

            if (productURL !== productDataTemp[2]) {
                formData.append('url', productURL);
            }

            if (productPrice !== productDataTemp[3]) {
                formData.append('price', productPrice);
            }

            if (!arraysAreEqual(personName, productDataTemp[4])) {
                personName.forEach((category) => {
                    formData.append('category', category);
                });
            }

            // Request pre-signed URLs for video and file
            const apiUrl = `${IP_ADD}/api/products/sign-url`;

            const payload = {
                files: [],
            };

            if (selectedVideo && selectedVideo !== productDataTemp[6]) {
                payload.files.push({
                    fileName: selectedVideo.name,
                    fileType: selectedVideo.type,
                });
            }

            if (selectedFile && selectedFile !== productDataTemp[7]) {
                payload.files.push({
                    fileName: selectedFile.name,
                    fileType: selectedFile.type,
                });
            }

            const response = await axios.post(apiUrl, payload, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            const signedUrls = response.data;

            // Upload video to S3 and append the URL directly to FormData
            if (selectedVideo && selectedVideo !== productDataTemp[6]) {
                const videoIndex = payload.files.findIndex(file => file.fileName === selectedVideo.name);
                if (videoIndex !== -1 && signedUrls[videoIndex]) {
                    const res = await axios.put(signedUrls[videoIndex].url, selectedVideo, {
                        headers: {
                            'Content-Type': selectedVideo.type,
                        },
                    });

                    formData.append('demoVideo', `https://${process.env.REACT_APP_AWS_BUCKET_NAME}.s3.amazonaws.com/${encodeURIComponent(selectedVideo.name)}`);
                }
            }

            // Upload file to S3 and append the URL directly to FormData
            if (selectedFile && selectedFile !== productDataTemp[7]) {
                const fileIndex = payload.files.findIndex(file => file.fileName === selectedFile.name);
                if (fileIndex !== -1 && signedUrls[fileIndex]) {
                    const res = await axios.put(signedUrls[fileIndex].url, selectedFile, {
                        headers: {
                            'Content-Type': selectedFile.type,
                        },
                    });

                    formData.append('productFiles', `https://${process.env.REACT_APP_AWS_BUCKET_NAME}.s3.amazonaws.com/${encodeURIComponent(selectedFile.name)}`);
                }
            }
            if (!arraysAreEqual(features, productDataTemp[8])) {
                features.forEach((feature) => {
                    formData.append('features', feature);

                });
            }

            if (!arraysAreEqual(perks, productDataTemp[9])) {
                perks.forEach((perk) => {
                    formData.append('perks', perk);

                });
            }
            if (!arraysAreEqual(uploadedImages, productDataTemp[5])) {
                uploadedImages.forEach((image, index) => {
                    if (typeof (image) === 'object') {
                        formData.append('images', image);
                        formData.append('imageIndex', index);
                    }
                });
            }
            if (!selectedVideo)
                formData.append('demoVideo', "")

            const success = await updateProduct(isEdit, formData);

            if (success) {
                setDataSubmission(false);
                setSnackbarMsg('Product updated successfully');
                setSnackbarSeverity('success');
                setOpen(true);
                setTimeout(() => {
                    navigate("/seller/products");
                }, 1000);
            } else {
                setSnackbarMsg('Failed to update product');
                setSnackbarSeverity('error');
                setOpen(true);
                setTimeout(() => {
                    navigate("/seller/products");
                }, 1000);
            }
        } catch (error) {
            setDataSubmission(false);
            setSnackbarMsg(error.message ? error.message : 'Failed to update product');
            setSnackbarSeverity('error');
            setOpen(true);
            setTimeout(() => {
                navigate("/seller/products");
            }, 1000);
            console.error('Error updating product:', error);
        }
    };

    const validateBeforePublish = async () => {
        const newErrorMessages = {
            productName: "",
            productDescription: "",
            productURL: "",
            productPrice: "",
            selectedCategories: "",
            selectedVideo: "",
            uploadedImages: "",
            selectedFile: "",
            features: "",
            perks: "",
        };

        let isValid = true;

        if (!productName) {
            newErrorMessages.productName = "Please enter a product name.";
            isValid = false;
        }

        if (!productDescription) {
            newErrorMessages.productDescription = "Please enter a product description.";
            isValid = false;
        }

        if (!productURL) {
            newErrorMessages.productURL = "Please enter a product URL.";
            isValid = false;
        } else if (!isValidURL(productURL)) {
            newErrorMessages.productURL = "Please enter a valid URL.";
            isValid = false;
        }

        if (!productPrice) {
            newErrorMessages.productPrice = "Please enter a product price.";
            isValid = false;
        }

        if (personName.length === 0) {
            newErrorMessages.selectedCategories = "Please select at least one category.";
            isValid = false;
        }

        if (uploadedImages.length === 0) {
            newErrorMessages.uploadedImages = "Please upload at least one image.";
            isValid = false;
        }
        if (type != 'website' && type != 'web') {
            if (!selectedFile) {
                newErrorMessages.selectedFile = "Please upload at least one product file.";
                isValid = false;
            }

            else if (selectedFile.size > 3 * 1024 * 1024 * 1024) {
                newErrorMessages.selectedFile = "Please upload a file of less than 3gb.";
                isValid = false;
            }
        }
        if (selectedVideo && selectedVideo.size > 500 * 1024 * 1024) {
            newErrorMessages.selectedVideo = "Please upload a video of less than 500mb.";
            isValid = false;
        }
        // if (features.length === 0) {
        //     newErrorMessages.features = "Please enter at least one feature.";
        //     isValid = false;
        // }

        // if (perks.length === 0) {
        //     newErrorMessages.perks = "Please enter at least one perk.";
        //     isValid = false;
        // }

        setErrorMessages(newErrorMessages);

        if (isValid) {
            try {
                setDataSubmission(true);
                if (!isEdit) {
                    // Make API call to check user's credit
                    const creditResponse = await axios.get(`${IP_ADD}/api/payment/credit/user/${userId}`);

                    if (creditResponse.status === 200 && creditResponse.data.response && creditResponse.data.response.amount >= 10000) {
                        // User has enough credit, call handleProductAddition
                        setErrorMessages('');
                        setDataSubmission(true);
                        await handleProductAddition();  // Ensure handleProductAddition is also asynchronous
                    } else {
                        // Credit not found or insufficient, open terms modal
                        setErrorMessages('');
                        setDataSubmission(true);
                        handleTermsModalOpen();
                    }
                } else {
                    // isEdit is true, call handleProductUpdate directly
                    setErrorMessages('');
                    setDataSubmission(true);
                    await handleProductUpdate();  // Ensure handleProductUpdate is also asynchronous
                }
            } catch (error) {
                // Handle API error
                console.error('Error checking user credit:', error);
                // Optionally set an error state or show an error message to the user
            }
        }
    };

    const isValidURL = (url) => {
        // Regular expression for a valid URL
        const urlRegex = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w.-]*)*\/?$/;
        return urlRegex.test(url);
    };

    const removeCategory = (nameToRemove) => {
        const updatedNames = personName.filter((name) => name !== nameToRemove);
        setPersonName(updatedNames);
    }

    useEffect(() => {
        // Check if any data has changed
        let hasDataChanged;
        if (type === 'desktop') {
            hasDataChanged =
                productName !== productDataTemp[0] ||
                productDescription !== productDataTemp[1] ||
                productURL !== productDataTemp[2] ||
                productPrice !== productDataTemp[3] ||
                !arraysAreEqual(personName, productDataTemp[4]) ||
                selectedVideo !== productDataTemp[6] ||
                !arraysAreEqual(uploadedImages, productDataTemp[5]) ||
                selectedFile != productDataTemp[7] ||
                !arraysAreEqual(features, productDataTemp[8]) ||
                !arraysAreEqual(perks, productDataTemp[9])
        }
        else {
            hasDataChanged =
                productName !== productDataTemp[0] ||
                productDescription !== productDataTemp[1] ||
                productURL !== productDataTemp[2] ||
                productPrice !== productDataTemp[3] ||
                !arraysAreEqual(personName, productDataTemp[4]) ||
                selectedVideo !== productDataTemp[6] ||
                !arraysAreEqual(uploadedImages, productDataTemp[5]) ||
                !arraysAreEqual(features, productDataTemp[8]) ||
                !arraysAreEqual(perks, productDataTemp[9])
        }


        setIsDataChanged(hasDataChanged);
    }, [
        productName,
        productDescription,
        productURL,
        productPrice,
        personName,
        selectedVideo,
        uploadedImages,
        productDataTemp,
        selectedFile,
        features,
        perks
    ]);

    useEffect(() => {

        if (productName) {
            setErrorMessages((prevErrors) => ({
                ...prevErrors,
                productName: "",
            }));
        }

        if (productDescription) {
            setErrorMessages((prevErrors) => ({
                ...prevErrors,
                productDescription: "",
            }));
        }

        if (productURL) {
            setErrorMessages((prevErrors) => ({
                ...prevErrors,
                productURL: "",
            }));
        }

        if (productPrice) {
            setErrorMessages((prevErrors) => ({
                ...prevErrors,
                productPrice: "",
            }));
        }

        if (personName.length > 0) {
            setErrorMessages((prevErrors) => ({
                ...prevErrors,
                selectedCategories: "",
            }));
        }

        if (uploadedImages.length > 0) {
            setErrorMessages((prevErrors) => ({
                ...prevErrors,
                uploadedImages: "",
            }));
        }
        if (selectedFile && selectedFile.size < 3 * 1024 * 1024 * 1024) {
            setErrorMessages((prevErrors) => ({
                ...prevErrors,
                selectedFile: "",
            }));
        }
        else if (selectedFile && selectedFile.size > 3 * 1024 * 1024 * 1024) {
            setErrorMessages((prevErrors) => ({
                ...prevErrors,
                selectedFile: "Please upload a file of less than 3gb.",
            }));
        }

        else if (selectedVideo && selectedVideo.size > 500 * 1024 * 1024) {
            setErrorMessages((prevErrors) => ({
                ...prevErrors,
                selectedVideo: "Please upload a video of less than 500mb.",
            }));
        }
        // if (features.length > 0) {
        //     setErrorMessages((prevErrors) => ({
        //         ...prevErrors,
        //         features: "",
        //     }));
        // }

        // if (perks.length > 0) {
        //     setErrorMessages((prevErrors) => ({
        //         ...prevErrors,
        //         perks: "",
        //     }));
        // }


    }, [productName,
        productDescription,
        productURL,
        productPrice,
        personName,
        selectedVideo,
        uploadedImages,
        selectedFile,
        features,
        perks])

    useEffect(() => {
        if (paymentFailSeller) {
            handleModalClose();
            setSnackbarMsg('Payment Failed');
            setSnackbarSeverity('error');
            // setPaymentDone(true);
            setOpen(true);
            setPaymentFailSeller(false);
        }

    }, [paymentFailSeller]);


    useEffect(() => {
        if (isEdit) {
            fetchData(isEdit)
                .then(() => setLoading(false))
                .catch((error) => {
                    console.error('API request error:', error);
                    setLoading(false);
                });
        } else {
            setLoading(false);
        }
    }, [isEdit]);


    return (
        <>
            <Helmet>
                <title>{isEdit ? 'Cornucopia AI | Edit Product' : 'Cornucopia AI | Add Product'}</title>
            </Helmet>
            <Snackbar
                open={open}
                autoHideDuration={2000}
                onClose={handleSnackBarClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={handleSnackBarClose}
                    severity={snackbarSeverity}
                >
                    {snackbarMsg}
                </MuiAlert>
            </Snackbar>
            <ProductAddedModal open={productModalOpen} onClose={handleProductModalClose} heading='Product Added Successfully' productId={productId} buttonText='Cancel' />
            <FlexDiv style={{ paddingTop: '1.2rem', gap: isLaptop ? '1rem' : '0.1rem', justifyContent: 'flex-end' }}>
                <UserProfileSeller />
            </FlexDiv>
            <FlexDiv style={{ gap: '0.6rem', paddingLeft: isLargeLaptop ? '' : '1.5rem', paddingTop: '0.5rem' }}>
                <button onClick={handleNavigate} style={{ display: 'contents' }}>
                    <BackDiv>
                        <KeyboardBackspaceIcon sx={{ color: '#777777' }} />
                    </BackDiv>
                </button>
                <div>
                    <Typography sx={BackToProduct}>{BACK_TO_PRODUCT_LIST}</Typography>
                    <Typography sx={AddProduct}>{isEdit ? 'Edit Product' : ADD_NEW_PRODUCT}</Typography>
                </div>
            </FlexDiv>

            <>
                <Grid container style={{ paddingTop: '2.6rem', paddingRight: '1.5rem', paddingLeft: isLargeLaptop ? '' : '1.5rem' }}>
                    <Grid item lg={5} md={12} sm={12} xs={12}>
                        <Typography sx={isTextFieldFocused ? { ...ProductText, color: '#780093' } : ProductText}>{PRODUCT_NAME}</Typography>
                        <TextField
                            type="text"
                            placeholder={ECOMMERCE_DASHBOARD}
                            variant="outlined"
                            value={productName}
                            onChange={(e) => setProductName(e.target.value)}
                            onFocus={handleNameTextFieldFocus}
                            onBlur={handleNameTextFieldBlur}
                            sx={{
                                width: '100%',
                                height: '2.875rem',
                                borderRadius: '0.4375rem',
                                '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    borderColor: '#9D40B1',
                                },
                                '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                                    borderColor: '#9D40B1',
                                },
                                '& input::placeholder': placeholderStyles,
                            }}
                        />
                        {errorMessages.productName && (
                            <Typography sx={{ color: 'red', marginTop: '1rem' }}>{errorMessages.productName}</Typography>
                        )}
                        <Typography sx={isProductTextareaFocused ? { ...ProductText, color: '#780093', marginTop: '2.3rem' } : { ...ProductText, marginTop: '2.3rem' }}>
                            {PRODUCT_DESCRIPTION}
                        </Typography>
                        <Textarea
                            minRows={5}
                            value={[productDescription]}
                            onChange={(e) => setProductDescription(e.target.value)}
                            onFocus={handleProductTextareaFocus}
                            onBlur={handleProductTextareaBlur}
                            sx={{
                                '--Textarea-focusedHighlight': '#9D40B1',
                                backgroundColor: '#FFFF',
                                border: '1px solid #D3D3D3',

                                '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#9D40B1' },
                                '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#9D40B1' }
                            }}
                        />
                        <ProductDescriptionLength>
                            {productDescription.split(/\s+/).filter((word) => word.length > 0).length.toString() + PRODUCT_DESC_LENGTH}
                        </ProductDescriptionLength>
                        {errorMessages.productDescription && (
                            <Typography sx={{ color: 'red', marginTop: '1rem' }}>{errorMessages.productDescription}</Typography>
                        )}
                        <FlexDiv style={{ marginTop: '1.4rem', marginBottom: '0.75rem', justifyContent: 'space-between' }}>
                            <Typography sx={isUrlFocused ? { ...ProductText, color: '#780093', marginTop: '0.6rem' } : { ...ProductText, marginTop: '0.6rem' }} >{PRODUCT_URL}</Typography>
                            <Switch color="secondary" />
                        </FlexDiv>

                        <TextField
                            type="text"
                            placeholder={HTTPS}
                            value={productURL}
                            onChange={(e) => setProductURL(e.target.value)}
                            onFocus={handleUrlFocus}
                            onBlur={handleUrlBlur}
                            sx={{
                                width: '100%',
                                height: '2.875rem',
                                borderRadius: '0.4375rem',
                                '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#9D40B1' },
                                '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#9D40B1' }
                            }}
                        />
                        {errorMessages.productURL && (
                            <Typography sx={{ color: 'red', marginTop: '1rem' }}>{errorMessages.productURL}</Typography>
                        )}
                        <Typography sx={{
                            ...ProductText,
                            marginTop: '2.3rem',
                            marginBottom: '0.75rem',
                            color: isDropdownOpen ? '#780093' : ProductText.color,
                        }}>{PRODUCT_CATEG}</Typography>
                        <FormControl
                            sx={{
                                width: '100%',
                                borderRadius: '0.4375rem',
                                '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'rgba(157, 64, 177, 0.45)',
                                },
                                '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    borderColor: '#9D40B1',
                                },
                                '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                                    borderColor: '#9D40B1',
                                },
                                '& .MuiSelect-icon': {
                                    color: '#9D40B173',
                                },
                            }}
                        >
                            {(personName.length === 0) &&
                                <InputLabel id="demo-multiple-checkbox-label"
                                    sx={{
                                        marginTop: '0.1rem',
                                        color: '#9D40B1',
                                        fontFamily: 'Poppins',
                                        fontSize: '0.9375rem',
                                        fontStyle: 'normal',
                                        fontWeight: 500,
                                        lineHeight: 'normal',

                                        "&.Mui-focused": {
                                            color: '#9D40B1', // Set the same color as the label color
                                        },
                                    }}
                                    shrink={false}
                                >
                                    {SELECT_CATEG}
                                </InputLabel>
                            }
                            <Select
                                labelId="demo-multiple-checkbox-label"
                                id="demo-multiple-checkbox"
                                multiple
                                value={personName}
                                onChange={handleChange}
                                onOpen={handleOpen}
                                onClose={handleClose}
                                renderValue={(selected) => selected.join(', ')}
                                MenuProps={MenuProps}
                            >
                                {names.map((name) => (
                                    <MenuItem key={name} value={name}>
                                        <Checkbox checked={personName.indexOf(name) > -1} />
                                        <ListItemText primary={name} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        {isMobile && Array.from({ length: (personName.length / 2) + (personName.length % 2) }).map((number, index) => (
                            <FlexDiv style={{ marginTop: '0.7rem', gap: '0.5rem' }} key={index}>
                                {personName.slice(index * 2, (2 * (index + 1)) <= personName.length ? (index * 2) + 2 : (index * 2) + 1).map((data) => (
                                    <TagDiv key={data} style={{ marginTop: '1.1rem' }}>
                                        <div style={{ margin: '0.5rem 0.3rem 0rem 0.5rem' }}>{data}</div>
                                        <IconButton style={{ marginLeft: '0.3rem' }} onClick={() => { removeCategory(data) }}>
                                            <CloseIcon sx={{ color: '#8F8F8F' }} />
                                        </IconButton>
                                    </TagDiv>
                                ))}
                            </FlexDiv>
                        ))}
                        {errorMessages.selectedCategories && (
                            <Typography sx={{ color: 'red', marginTop: '1rem' }}>{errorMessages.selectedCategories}</Typography>
                        )}
                        <Typography sx={isPriceFocused ? { ...ProductText, color: '#780093', marginTop: '2.3rem' } : { ...ProductText, marginTop: '2.3rem' }}>{PRICE}</Typography>


                        <TextField
                            type="number"
                            value={productPrice}
                            onChange={(e) => setProductPrice(e.target.value)}
                            onFocus={handlePriceFocus}
                            onBlur={handlePriceBlur}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <div style={{ ...PricePrefix, marginLeft: '-0.8rem' }}>
                                            <Typography sx={DollarSign}>$</Typography>
                                        </div>
                                    </InputAdornment>
                                ),
                            }}
                            sx={{
                                width: '100%',
                                height: '2.875rem',
                                borderRadius: '0.4375rem',
                                '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#9D40B1' },
                                '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#9D40B1' }
                            }}
                        />
                        {errorMessages.productPrice && (
                            <Typography sx={{ color: 'red', marginTop: '1rem' }}>{errorMessages.productPrice}</Typography>
                        )}
                        <Typography sx={isFeaturesFocused ? { ...ProductText, color: '#780093', marginTop: '2.3rem' } : { ...ProductText, marginTop: '2.3rem' }}>Features</Typography>
                        <TextField
                            fullWidth
                            value={featureInput}
                            onChange={handleFeatureInputChange}
                            onFocus={handleFeaturesFocused}
                            onBlur={handleFeaturesBlur}
                            InputProps={{
                                endAdornment: (
                                    <Button onClick={handleAddFeature} style={featureAddBtn}>
                                        Add
                                    </Button>
                                ),
                            }}
                            sx={{
                                width: '100%',
                                height: '2.875rem',
                                borderRadius: '0.4375rem',
                                '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#9D40B1' },
                                '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#9D40B1' }
                            }}
                        />
                        {errorMessages.features && (
                            <Typography sx={{ color: 'red', marginTop: '1rem' }}>{errorMessages.features}</Typography>
                        )}
                        <List>
                            {features.map((feature, index) => (
                                <ListItem key={index}>
                                    <ListItemIcon>
                                        <DoneIcon
                                            sx={{
                                                color: index % 2 === 0 ? '#8F8F8F' : '#9D40B1', width: '1.5rem', height: '1.5rem'
                                            }}
                                        />
                                    </ListItemIcon>
                                    <ListItemText primary={feature} />
                                    <CloseIcon
                                        sx={{
                                            cursor: 'pointer',
                                            color: '#D95656',
                                        }}
                                        onClick={() => removeFeature(index)}
                                    />
                                </ListItem>
                            ))}
                        </List>

                        <Typography sx={isPerksFocused ? { ...ProductText, color: '#780093', marginTop: '2.3rem' } : { ...ProductText, marginTop: '2.3rem' }}>Perks and Benefits</Typography>
                        <TextField
                            fullWidth
                            value={perksInput}
                            onChange={handlePerksInputChange}
                            onFocus={handlePerksFocused}
                            onBlur={handlePerksBlur}
                            InputProps={{
                                endAdornment: (
                                    <Button onClick={handleAddPerks} style={featureAddBtn}>
                                        Add
                                    </Button>
                                ),
                            }}
                            sx={{
                                width: '100%',
                                height: '2.875rem',
                                borderRadius: '0.4375rem',
                                '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#9D40B1' },
                                '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#9D40B1' }
                            }}
                        />
                        {errorMessages.perks && (
                            <Typography sx={{ color: 'red', marginTop: '1rem' }}>{errorMessages.perks}</Typography>
                        )}
                        <List>
                            {perks.map((perk, index) => (
                                <ListItem key={index}>
                                    <ListItemIcon>
                                        <DoneIcon
                                            sx={{
                                                color: index % 2 === 0 ? '#8F8F8F' : '#9D40B1', width: '1.5rem', height: '1.5rem'
                                            }}
                                        />
                                    </ListItemIcon>
                                    <ListItemText primary={perk} />
                                    <CloseIcon
                                        sx={{
                                            cursor: 'pointer',
                                            color: '#D95656',
                                        }}
                                        onClick={() => removePerk(index)}
                                    />
                                </ListItem>
                            ))}
                        </List>

                    </Grid>
                    <Grid item lg={7} md={12} sm={12} xs={12} marginTop={isDesktop ? '' : '2.5rem'}>
                        <FlexDiv style={{ marginLeft: isDesktop ? '1.5rem' : '', alignItems: "flex-start" }}>
                            <Typography sx={ProductText} style={{ height: '21px' }}>{PRODUCT_IMAGES}</Typography>
                            <Tooltip title="You can add 4 images at max">
                                <img src={Caution} alt="" style={{ marginTop: '-0.2rem', marginLeft: '1rem' }} />
                            </Tooltip>
                        </FlexDiv>
                        <div style={{ margin: isDesktop ? '0rem 0rem 0rem 1.5rem' : '0rem' }}>
                            <PhotoUploadBox uploadedImages={uploadedImages} setUploadedImages={setUploadedImages} editing={isEdit ? true : false} productID={isEdit} />
                            {errorMessages.uploadedImages && (
                                <Typography sx={{ color: 'red', marginTop: '1rem' }}>{errorMessages.uploadedImages}</Typography>
                            )}
                        </div>
                        <FlexDiv style={{ marginLeft: isDesktop ? '1.5rem' : '', alignItems: "flex-start", marginTop: '1.21rem' }}>
                            <Typography sx={ProductText} style={{ height: '21px' }}>{PRODUCT_DEMO}</Typography>
                            <Tooltip title="You can add only 1 video">
                                <img src={Caution} alt="" style={{ marginTop: '-0.2rem', marginLeft: '1rem' }} />
                            </Tooltip>
                        </FlexDiv>
                        <div style={{ margin: isDesktop ? '0rem 0rem 0rem 1.5rem' : '0rem' }}>
                            <VideoUploadBox setSelectedVideo={setSelectedVideo} selectedVideo={selectedVideo} />
                            {errorMessages.selectedVideo && (
                                <Typography sx={{ color: 'red', marginTop: '1rem' }}>{errorMessages.selectedVideo}</Typography>
                            )}
                        </div>
                        {type === 'desktop' &&
                            <>
                                <FlexDiv style={{ marginLeft: isDesktop ? '1.5rem' : '', alignItems: "flex-start", marginTop: '1.21rem' }}>
                                    <Typography sx={ProductText} style={{ height: '21px' }}>Product File</Typography>
                                    <Tooltip title="You can add only 1 product file">
                                        <img src={Caution} alt="" style={{ marginTop: '-0.2rem', marginLeft: '1rem' }} />
                                    </Tooltip>
                                </FlexDiv>
                                <div style={{ margin: isDesktop ? '0rem 0rem 0rem 1.5rem' : '0rem' }}>
                                    <ZipUploadBox setSelectedFile={setSelectedFile} selectedFile={selectedFile} />
                                    {errorMessages.selectedFile && (
                                        <Typography sx={{ color: 'red', marginTop: '1rem' }}>{errorMessages.selectedFile}</Typography>
                                    )}
                                </div>
                            </>
                        }
                        {!isEdit &&
                            <FlexDiv style={{ margin: isDesktop ? '1rem 0rem 0rem 1rem' : '1rem 0rem 0rem 0rem' }}>
                                <Checkbox color="secondary" onChange={(e) => setIsShareable(e.target.checked)} />
                                <Typography sx={ProductText} style={{ margin: '0.6rem 0rem 0rem 0rem', fontSize: '1rem' }}>{CAN_WE_USE}</Typography>
                            </FlexDiv>
                        }
                    </Grid>
                </Grid>
                <FlexDiv style={{ justifyContent: 'flex-end', marginTop: '2.5rem', gap: '1.3rem', paddingRight: '1.5rem', paddingBottom: '2rem' }}>
                    <button style={{ display: 'contents' }}>
                        <DiscardBtn onClick={() => { navigate("/seller/products") }}>
                            {DISCARD}
                        </DiscardBtn>
                    </button>
                    <button style={{ display: 'contents' }} onClick={() => validateBeforePublish()} disabled={!isDataChanged || dataSubmission}>

                        {!dataSubmission ? <PublishProduct>{isEdit ? 'Edit Product' : 'Publish Product'}</PublishProduct> : <PublishProduct><CircularProgress color="secondary" style={{ width: '27px', height: '25px' }} /></PublishProduct>}
                    </button>
                </FlexDiv>
            </>

            <Modal
                isOpen={isModalOpen}
                onRequestClose={handleModalClose}
                contentLabel="Subscribe Modal"
                style={{
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        zIndex: 1000
                    },
                    content: {
                        width: isSmallMobile ? '95%' : '400px', // Adjust the width as per your preference
                        height: isSmallMobile ? 'max-content' : '350px',
                        margin: 'auto',
                        borderRadius: '0.75rem',
                        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                        padding: '1.5rem',
                        inset: isSmallMobile ? '0px' : '40px',
                        top: isSmallMobile ? '25%' : '',
                        left: isSmallMobile ? '2.5%' : '',
                        zIndex: 1001

                    },
                }}
            >
                <img src={CardIcon} alt="" style={{
                    width: '3.5rem',
                    height: '3.5rem',
                    borderRadius: '0.5rem',
                    border: '8px solid var(--primary-50, #F9F5FF)',
                    background: 'var(--primary-100, #F4EBFF)',
                    marginBottom: '1.25rem',
                }} />
                <h2 style={{
                    color: '#46137C',
                    fontFamily: 'Poppins',
                    fontSize: '1.5rem',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    lineHeight: 'normal',
                    display: 'flex',

                }}>
                    100$ per product
                </h2>
                <div style={enterCard}>Enter your card details</div>
                <CardElement
                    options={{
                        style: {
                            base: {
                                fontSize: '16px',
                                color: '#424770',
                                '::placeholder': {
                                    color: '#aab7c4',
                                },
                            },
                            invalid: {
                                color: '#9e2146',
                            },
                        },
                    }}
                />
                <div style={{ display: 'flex', marginTop: isSmallMobile ? '3rem' : '1.2rem' }}>
                    <button
                        onClick={handleModalClose}
                        style={{
                            width: '50%',
                            borderRadius: '0.4375rem',
                            background: '#EDEDED',
                            color: '#8F8F8F',
                            fontFamily: 'Poppins',
                            fontSize: '0.8125rem',
                            fontStyle: 'normal',
                            fontWeight: 600,
                            lineHeight: 'normal',
                            marginTop: '3rem',
                            border: 'none',
                            display: 'block',
                            padding: '8px',
                            marginRight: '0.75rem'
                        }}
                    >
                        Cancel
                    </button>
                    <button
                        onClick={(e) => handlePayment(e)}
                        style={{
                            width: '50%',
                            borderRadius: '0.4375rem',
                            background: '#3F0065',
                            color: '#FFF',
                            fontFamily: 'Poppins',
                            fontSize: '0.8125rem',
                            fontStyle: 'normal',
                            fontWeight: 600,
                            lineHeight: 'normal',
                            marginTop: '3rem',
                            border: 'none',
                            display: 'block',
                            // padding: '8px'
                        }}
                    >
                        {!loading ? 'Pay Now' : <CircularProgress color="secondary" style={{ width: '15px', height: '15px' }} />}
                    </button>
                </div>

            </Modal>
            <TermsConditionsModal
                isOpen={isTermsModalOpen}
                onClose={handleTermsModalClose}
                onAccept={handleModalOpen}
            />
        </>
    )
}
function arraysAreEqual(arr1, arr2) {
    if (arr1.length !== arr2.length) {
        return false;
    }

    for (let i = 0; i < arr1.length; i++) {
        if (arr1[i] !== arr2[i]) {
            return false;
        }
    }

    return true;
}
export default AddNewProduct;