import React, { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import NavBar from '../../components/Navbar';
import Footer from '../../components/Footer';
import FooterDivider from '../../components/FooterDivider';
import LandingPageInputBox from '../../components/LandingPage/LandingPageInputBox';
import TrendingProducts from '../../components/LandingPage/TrendingProducts';
import ProductsFilters from '../../components/ViewAllProducts/ProductsFilters';
import NoProduct from '../../components/SellerDashboard/NoProducts';
import { fetchProducts } from '../../Services/Products';
import CustomPagination from '../../components/CustomPagination';
import { Helmet } from 'react-helmet';
import { HEADER_DATA } from './constants';
import SpinnerComponent from '../../components/Spinner';
import { FlexDiv } from '../../assets/styles/styles';
import { InputFilterDiv } from '../../assets/styles/styles';
import ProductType from './ProductType';
import { Container } from '@mui/material';
import { useNotification } from '../../Context/NotificationContext';
import Dropdown from './Dropdown';

const BuyerProducts = () => {
    const [searchQuery, setSearchQuery] = useState('');
    const isMobile = useMediaQuery({ maxWidth: 600 });
    const isTab = useMediaQuery({ maxWidth: 700 });
    const [minPrice, setMinPrice] = useState(0);
    const [maxPrice, setMaxPrice] = useState(10000);
    const [isFreeChecked, setIsFreeChecked] = useState(true);
    const [products, setProducts] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [sortBy, setSortBy] = useState(''); // State variable to hold selected sort option
    const [loading, setLoading] = useState(true); // Set initial value to false
    // const [selectedCategory, setSelectedCategory] = useState([]);
    const [type, setType] = useState('')
    const { selected, selectedCategory, setSelectedCategory } = useNotification();
    const handlePageChange = (event, newPage) => {
        setCurrentPage(newPage);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [currentPage]);

    const handleSearch = (searchTerm) => {
        setSearchQuery(searchTerm);
    };

    const getProducts = async () => {
        try {

            const limit = isMobile ? 5 : 16; // Set limit based on screen size

            const { products, totalPages } = await fetchProducts({
                searchQuery,
                minPrice,
                maxPrice,
                selectedCategory,
                currentPage,
                limit,
                selected,
                sortBy
            });
            if (currentPage > totalPages)
                setCurrentPage(1);

            setProducts(products);
            setTotalPages(totalPages);

        } catch (error) {
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getProducts();
    }, [currentPage, searchQuery, maxPrice, minPrice, selectedCategory, isMobile, selected, sortBy]);

    return (
        <>
            <Container maxWidth={false} sx={{ maxWidth: '1800px' }} disableGutters>
                <Helmet>
                    <title>Cornucopia AI | Products</title>
                </Helmet>
                <div style={styles.container}>
                    <NavBar header_data={HEADER_DATA} header_button={{ label: 'Join the venture', navigation_url: '/buyerseller' }} mobile_nav_height={'260px'} />
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '4rem', marginTop: isTab?'2rem':'6rem' }}>
                        {/* <InputFilterDiv style={{ marginBottom: '-2.5rem', paddingLeft: !isTab ? '6.38rem' : '0rem', paddingRight: !isTab ? '4rem' : '0rem', marginLeft: isTab ? '1rem' : '', marginRight: isTab ? '1rem' : '' }}>
                            <ProductType type={type} setType={setType} />
                        </InputFilterDiv> */}
                        <LandingPageInputBox padding_top='0rem' searchQuery={searchQuery} setSearchQuery={setSearchQuery} onSearch={handleSearch} />
                    </div>
                    <FlexDiv style={{ justifyContent: 'center', alignItems: isTab?'center':'flex-start', flexDirection: isTab ? 'column' : 'row', paddingRight: isTab ? '0rem' : '4rem', paddingLeft: isTab ? '0rem' : '0.5rem', marginBottom:isTab?'2rem':'' }}>
                        <div style={{ ...styles.filtersContainer, marginLeft: !isTab ? '2.5rem' : '' }}>
                            <ProductsFilters
                                setMinPrice={setMinPrice}
                                setMaxPrice={setMaxPrice}
                                isFreeChecked={isFreeChecked}
                                setIsFreeChecked={setIsFreeChecked}
                            // selectedCategory={selectedCategory}
                            // setSelectedCategory={setSelectedCategory}
                            />
                        </div>
                        <Dropdown setSortBy={setSortBy} />
                    </FlexDiv>
                    {loading ? (
                        <div style={styles.loadingContainer}>
                            {/* <CircularProgress color="secondary" style={{ width: '27px', height: '25px' }} /> */}
                            <SpinnerComponent />
                        </div>
                    )
                        : products?.length === 0 && !loading ? (
                            <NoProduct width="85%" />
                        ) : (
                            <>
                                {!loading && (
                                    <TrendingProducts totalTrendingProducts={16} trendingProductsText={'AI Products'} products={products} viewMore={false} />
                                )}
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flex: '1', marginTop: isMobile ? '8rem' : '2rem' }}>
                                    <CustomPagination count={totalPages} page={currentPage} onChange={handlePageChange} />
                                </div>
                            </>
                        )}
                </div >
            </Container>
            <FooterDivider />
            <Footer />
        </>
    );
};

const styles = {
    container: {
        marginBottom: '-200px',
    },
    filtersContainer: {

        width: '95%',
    },
    loadingContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
    },
    paginationContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flex: '1',
        marginTop: '2rem'
    },
};

export default BuyerProducts;
