import ProductsDollar from '../../assets/images/expenses_dollar.svg';
import SalesLine from '../../assets/images/sales_line.svg';
import SalesImg from '../../assets/images/sales_image.svg';
import ReviewLine from '../../assets/images/review_line.svg';
import ReviewImg from '../../assets/images/review_image.svg';
import styled from "@emotion/styled";
import ProductsLine from "../../assets/images/expenses_line.svg";

export const Analytics = styled.div`
    display: flex;
    border-radius: 0.5625rem;
    background: #FFF;
    border: 1px solid #EDEDED;
    padding: 2rem;
`;

export const AnalyticsHeading = {
    color: "#666",
    fontFamily: "Poppins",
    fontSize: "0.9375rem",
    fontWeight: 400
}

export const AnalyticsNumber = {
    color: "#000",
    fontFamily: "Poppins",
    fontSize: " 1.375rem",
    fontWeight: 500
}

export const SellerHeading = {
    color: "#4E0081",
    textAlign: "center",
    fontFamily: "Poppins",
    fontSize: "1.5625rem",
    fontWeight: 500
}

export const SellerDesc = {
    color: "#9C9C9C",
    textAlign: "center",
    fontFamily: "Poppins",
    fontSize: "0.9375rem",
    fontWeight: 500
}

export const HEADER_DATA = [
    {
        id: '1',
        name: 'Home',
        margin_right: '2rem',
        navigation_url: '/'
    },
    {
        id: '2',
        name: 'Products',
        margin_right: '2rem',
        navigation_url: '/products'
    },
    {
        id: '3',
        name: 'Sellers',
        margin_right: '2rem',
        navigation_url: '/sellers'
    },
    {
        id: '4',
        name: 'Login',
        margin_right: '0rem',
        navigation_url: '/login'
    },
];

export const AnalyticsData = [
    {
        id: 1,
        line: ProductsLine,
        heading: 'Total Products',
        num: '1,456',
        image: ProductsDollar
    },
    {
        id: 2,
        line: SalesLine,
        heading: 'Total Sales',
        num: '4,889',
        image: SalesImg
    },
    {
        id: 3,
        line: ReviewLine,
        heading: 'Total Reviews',
        num: '4,889',
        image: ReviewImg
    }
];