export const HEADER_DATA = [
    {
        id: '1',
        name: 'Home',
        margin_right: '2rem',
        navigation_url: '/'
    },
    {
        id: '2',
        name: 'Products',
        margin_right: '2rem',
        navigation_url: '/products'
    },
    {
        id: '3',
        name: 'Sellers',
        margin_right: '0rem',
        navigation_url: '/sellers'
    }
];
