import { Routes, Route, Navigate } from "react-router-dom";
import Login from "../Pages/Login/Login";
import SellerSignUp from "../Pages/SellerSignup/SellerSignUp";
import SignUp from "../Pages/Signup/SignUp";
import BuyerSeller from "../Pages/BuyerSeller/buyerSeller";
import OnBoard from '../Pages/OnBoard/OnBoard'
import SellerDashboard from "../Pages/SellerDashboard/SellerDashboard";
import LandingPage from "../Pages/LandingPage/LandingPage";
import Dashboard from "../components/SellerDashboard/Dashboard/Dashboard";
import Products from "../components/SellerDashboard/Products/Products";
import Transactions from "../components/SellerDashboard/Transactions/Transactions";
import BuyerProducts from "../Pages/BuyerProducts/BuyerProducts";
import AllSellers from "../Pages/AllSellers/AllSellers";
import AddNewProduct from "../components/SellerDashboard/AddNewProduct";
import Settings from "../components/SellerDashboard/Settings/Settings";
import BuyerProductDetails from "../Pages/BuyerProductDetails/BuyerProductDetails";
import SellerDetails from "../Pages/SellerDetails/SellerDetails";
import SellerProductDetails from "../components/SellerDashboard/SellerProductDetails";
import BuyerPurchasedProducts from "../Pages/BuyerPurchasedProducts/BuyerPurchasedProduct";
import InboxBuyer from "../Pages/InboxBuyer/InboxBuyer";
import { ProtectedRoute, SellerProtectedRoute, BuyerProtectedRoute, BuyerProtectedRouteTwo, SubscriptionProtectedRoute } from "./ProtectedRoutes";
import SellerInbox from "../components/SellerDashboard/SellerInbox";
import ForgotPassword from "../Pages/ForgetPassword/ForgotPassword"
import TokenVerify from "../Pages/TokenVerify/TokenVerify";
import SelectRole from "../Pages/SelectRole/SelectRole";
import WebOnboard from "../Pages/WebOnboard";
import Integration from "../components/SellerDashboard/Integration/Integration";
import EarningGuidelines from "../components/SellerDashboard/EarningGuidelines/EarningGuidelines";
import BuyerSettings from "../Pages/BuyerSettings/BuyerSettings";
const Router = () => (
    <Routes>
        <Route path="/" element={
            <BuyerProtectedRoute>
                <LandingPage />
            </BuyerProtectedRoute>
        } />
        <Route path="/login" element={
            <ProtectedRoute>
                <Login />
            </ProtectedRoute>
        } />
        <Route path="/selectrole" element={
            <SelectRole />
        } />
        <Route path="/signup-seller" element={
            <ProtectedRoute>
                <SellerSignUp />
            </ProtectedRoute>
        } />
        <Route path="/reset-password" element={
            <ProtectedRoute>
                <ForgotPassword />
            </ProtectedRoute>
        } />
        <Route path="/buyerseller" element={
            <ProtectedRoute>
                <BuyerSeller />
            </ProtectedRoute>
        } />
        <Route path="/verify-email" element={<TokenVerify />} />
        <Route path="/onboard" element={
            <SubscriptionProtectedRoute>
                <OnBoard />
            </SubscriptionProtectedRoute>
        } />
        <Route path="/signup" element={
            <ProtectedRoute>
                <SignUp />
            </ProtectedRoute>
        } />
        <Route path="*" element={<Navigate to="/" />} />
        <Route path="/products" element={
            <BuyerProtectedRoute>
                <BuyerProducts />
            </BuyerProtectedRoute>
        } />
        <Route path="/settings" element={
            <BuyerProtectedRoute>
                <BuyerSettings />
            </BuyerProtectedRoute>
        } />
        <Route path="/websiteOnboarding" element={
            <BuyerProtectedRouteTwo>
                <WebOnboard />
            </BuyerProtectedRouteTwo>
        } />
        <Route path="/sellers" element={
            <BuyerProtectedRoute>
                <AllSellers />
            </BuyerProtectedRoute>
        } />
        <Route path="product-details/:id" element={
            <BuyerProtectedRoute>
                <BuyerProductDetails />
            </BuyerProtectedRoute>
        } />
        <Route path="/seller-details/:id" element={
            <BuyerProtectedRoute>
                <SellerDetails />
            </BuyerProtectedRoute>
        } />
        <Route path="/my-products" element={
            <BuyerProtectedRoute>
                <BuyerPurchasedProducts />
            </BuyerProtectedRoute>
        } />
        <Route path="/seller" element={
            <SellerProtectedRoute>
                <SellerDashboard />
            </SellerProtectedRoute>
        } >
            <Route index element={<Dashboard />} />
            <Route path="products" element={<Products />} />
            <Route path="transactions" element={<Transactions />} />
            <Route path="products/add/:type" element={<AddNewProduct />} />
            <Route path="products/:productId" element={<SellerProductDetails />} />
            <Route path="settings" element={<Settings />} />
            <Route path="inbox" element={<SellerInbox />} />
            <Route path="integration" element={<Integration />} />
            <Route path="earnings" element={<EarningGuidelines />} />

        </Route>
        <Route path="/inboxbuyer" element={
            <BuyerProtectedRouteTwo>
                <InboxBuyer />
            </BuyerProtectedRouteTwo>
        } />
    </Routes>
)
export default Router;