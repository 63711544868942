import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import { useMediaQuery } from "react-responsive";
import CardContent from '@mui/material/CardContent';
import DoneIcon from '@mui/icons-material/Done';
import { LoginBoxBtn } from '../../assets/styles/styles';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import PurchaseProductImg from '../../assets/images/purchase_product.svg';
import DropMessage from '../../assets/images/drop_message.svg'
import { BASE_PRICE, BORIS_NISI, BUSINESS_GROWTH, DOLLAR, DROP_A_MSG, EXTEND_SUPPORT, JAMES_AND_LAURA, LEAVE_A_MSG, LOREM_IPSUM_DOLOR, LOREM_IPSUM_DOLOR_SIT, PRICE_TAG_NEW, PRICE_TAG_OLD, PURCHASE_PRODUCT, SELLER_INFO, UIS_AUTE, MONTHS, MESSAGE, TERMS_CONDITION, AGREE_TERMS, CANCEL } from '../../constants/text';
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import Modal from "react-modal";
import CircularProgress from "@mui/material/CircularProgress";
import { Snackbar } from "@mui/material";
import MuiAlert from '@mui/material/Alert';
import CardIcon from "../../assets/images/card_icon.svg"
import { useNavigate } from "react-router-dom";
import TermsConditionsModal from './TermsAndCondition';
import { buyProductApi } from '../../Services/Payment';
import { fetchNotificationsApi, sendNotificationApi } from '../../Services/Notification';
import { useNotification } from '../../Context/NotificationContext';
import axios from 'axios';
import { IP_ADD } from '../../constants/api';
import Avatar from '../../assets/images/male-avatar.png';

const customCardStyle = {
    borderRadius: '1.125rem',
    border: 'solid 1px rgba(90, 0, 144, 0.15)',
};

const contentStyle = {
    padding: '1.7rem 2rem', // top/right/bottom/left
};

const labelStyle = {
    color: '#6D0085',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
    marginTop: '2rem',
};

const priceStyle = {
    color: '#6D0085',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
    marginTop: '1.63rem',
};

const lineStyle = {
    width: '21.6875rem',
    height: '0.0625rem',
    background: '#DBDBDB',
};

const tickStyle = {
    width: '0.95988rem',
    height: '0.72044rem',
    fill: '#3F0065',
};

const bulletContentStyle = {
    color: '#888',
    fontfamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: 'normal',
    marginTop: '1.4rem',

};

const sellerInfo = {
    color: '#3F0065',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: 'normal',
};

const imageStyle = {
    width: '7rem',
    height: '6rem',
    borderRadius: '0.3125rem',
};

const Jamesandlaura = {
    color: '#7300BE',
    fontFamily: 'Poppins',
    fontSize: '0.75rem',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: 'normal',
};

const BusinessGrowth = {
    color: '#2D2D2D',
    fontFamily: 'Poppins',
    fontSize: '0.875rem',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
};

const BusniessGrowthSubtext = {
    color: '#757474',
    fontFamily: 'Poppins',
    fontSize: '0.6875rem',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',

};

const textAreaStyle = {
    width: '21.8125rem',
    height: '9.375rem',
    borderRadius: '0.4375rem',
    padding: '1.25rem',
    resize: 'none',
    color: '#858585', // Placeholder text color
    fontFamily: 'Poppins',
    fontSize: '1.0625rem',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
};

const dollarStyle = {
    color: '#6D0085',
    fontFamily: 'Poppins',
    fontSize: '0.9375rem',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: 'normal',
};

const enterCard = {
    color: 'var(--gray-500, #667085)',
    fontFamily: 'Poppins',
    fontSize: '0.875rem',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '1.25rem',
    marginTop: '0.5rem',
    marginBottom: '2.3rem'
};

const TokenExpired = (tokenDate) => {
    const convertedTime = new Date(tokenDate);
    const currentTime = new Date();
    if (currentTime >= convertedTime) {
        return true;
    }
    return false;
}

const BasePriceCard = ({ productName, price, sellerId, productId, setProductBought, firstName, lastName, profilePicture, companyName, bio }) => {
    const [extendSupport, setExtendSupport] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isTermsModalOpen, setIsTermsModalOpen] = useState(false);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [sendingMsg, setSendingMsg] = useState(false);
    const [message, setMessage] = useState('');
    const [snackbarMsg, setSnackbarMsg] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [isFocused, setIsFocused] = useState(false);
    const [isExpired, setIsExpired] = useState(true);
    const [productBuySuccess, setProductBuySuccess] = useState(false);
    const userData = (localStorage.getItem('access-token'));
    const isSmallMobile = useMediaQuery({ maxWidth: 450 });
    const stripe = useStripe();
    const elements = useElements();
    const { paymentFail, setPaymentFail, setPaymentSuccess, paymentSuccess, socket, newProductId } = useNotification();
    const { notification, notificationUpdated, setNotification, setNotificationUpdated, setNewProductId } = useNotification();
    const {
        setFetchAgain,
    } = useNotification();
    const user = JSON.parse(localStorage.getItem('Cornucopia-user'));
    const navigate = useNavigate();

    const handleModalOpen = async (e) => {
        // setSelectedPrice(priceId)
        if (!sellerId || !productId) {
            navigate("/login");
            return;
        }
        handleTermsModalClose();
        setIsModalOpen(true);
    };
    const handleTextareaChange = (event) => {
        setMessage(event.target.value);
    };
    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleBlur = () => {
        setIsFocused(false);
    };
    const handleTermsModalOpen = () => {
        if (!sellerId || !productId) {
            navigate("/login");
            return;
        }
        setIsTermsModalOpen(true);
    };

    const handleTermsModalClose = () => {
        setIsTermsModalOpen(false);
    };

    const handleModalClose = () => {
        setIsModalOpen(false);
    };
    useEffect(() => {
        if (paymentFail) {
            setLoading(false);
            setSnackbarMsg("Payment Failed");
            setSnackbarSeverity('error');
            setOpen(true);
            setPaymentFail(false);
        }
        if (paymentSuccess) {
            setLoading(false);
            handleModalClose();
            setSnackbarMsg('Payment Successful');
            setSnackbarSeverity('success');
            setOpen(true);
            setTimeout(() => {
                setProductBought(true);
            }, 1500);
            setPaymentSuccess(false);
        }
    }, [paymentFail, paymentSuccess]);

    const refreshtoken = () => {
        return new Promise(async (resolve, reject) => {
            try {
                const token = localStorage.getItem('refresh-token');
                const response = await axios.post(`${IP_ADD}/api/auth/refresh-tokens`, {
                    refreshToken: token
                });

                if (response.status === 200) {
                    localStorage.setItem('access-token', response.data.response.response.tokens.access.token);
                    localStorage.setItem('refresh-token', response.data.response.response.tokens.refresh.token);
                    localStorage.setItem('access-token-expiry', response.data.response.response.tokens.access.expires);
                    localStorage.setItem('refresh-token-expiry', response.data.response.response.tokens.refresh.expires);
                    setIsExpired(false);

                    // Resolve the promise with the response data
                    resolve(response.data);
                } else {
                    // Reject the promise with an error message
                    reject(new Error('Token refresh failed.'));
                }
            } catch (error) {
                // Reject the promise with the error
                reject(error);
            }
        });
    };

    const accessChat = async () => {
        if (message === '') {
            setSnackbarMsg("Please enter a message");
            setSnackbarSeverity('error');
            setOpen(true);
            return;
        }
        if (!userData) {
            setSnackbarMsg("Please sign in to send a message");
            setSnackbarSeverity('error');
            setOpen(true);
            return;
        }
        const refreshExpiryToken = localStorage.getItem('refresh-token-expiry');
        const convertedTime = new Date(refreshExpiryToken);
        const currentTime = new Date();
        if (currentTime >= convertedTime) {
            localStorage.removeItem('access-token');
            localStorage.removeItem('refresh-token');
            localStorage.removeItem('access-token-expiry');
            localStorage.removeItem('refresh-token-expiry');
            localStorage.removeItem('Cornucopia-user');
            navigate('/login');
            return;
        }
        const accessExpiryToken = localStorage.getItem('access-token-expiry');
        if (TokenExpired(accessExpiryToken)) {
            try {
                // Assuming refreshtoken returns a promise
                await refreshtoken();
            } catch (refreshError) {
                console.log('Error refreshing token', refreshError);
                // Handle error if needed
            }
        }
        const userId = sellerId;
        try {

            setSendingMsg(true);
            const userData = (localStorage.getItem('access-token'));
            let config = {
                headers: {
                    "Content-type": "application/json",
                    Authorization: `Bearer ${userData}`,
                },
            };
            const response = await axios.post(`${IP_ADD}/api/chats`, { userId }, config);
            const chatId = response.data.response._id;
            const formData = new FormData();

            formData.append('content', message);
            formData.append('chatId', chatId);
            config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${userData}`,
                },
            };

            const { data } = await axios.post(
                `${IP_ADD}/api/messages`,
                formData,
                config
            );
            if (socket && socket.connected) {
                socket.emit('event', { type: 'message', data: data.response });
            }
            setMessage('')
            setSnackbarMsg("Message Delivered");
            setSnackbarSeverity('success');
            setOpen(true);
            const notificationApiResult = await sendNotificationApi(
                sellerId,
                message,
                user.response.id,
                "message"
            );
            if (!notificationApiResult.success) {
                console.log("Error in notification", notificationApiResult.error);
            }
        } catch (error) {
            console.log(error);
            setSnackbarMsg("Message Failed");
            setSnackbarSeverity('error');
            setOpen(true);
            throw error;
        } finally {
            setSendingMsg(false);
        }
    };

    const handleBuyProduct = async (e) => {
        setLoading(true);

        const cardElement = elements.getElement(CardElement);
        const { paymentMethod, error } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
        });

        if (error) {
            console.error('Error creating payment method:', error);
            setSnackbarMsg(error.message);
            setSnackbarSeverity('error');
            setOpen(true);
            setLoading(false);
            return;
        }

        const apiResponse = await buyProductApi({
            sellerId,
            productId,
            price,
            paymentMethod,
            user,
        });


        if (apiResponse.success && apiResponse.data.message != 'Something went wrong') {


            // Use sendNotificationApi function
            if (apiResponse.data && apiResponse.data.response && apiResponse.data.response.metadata) {
                const notificationApiResult = await sendNotificationApi(
                    apiResponse.data.response.metadata.seller_id,
                    productName,
                    user.response.id,
                    'payment'
                );

                setProductBuySuccess(true);
                if (!notificationApiResult.success) {
                    console.error('Error sending notification:', notificationApiResult.error);
                }
            }
        } else {
            console.error('Error in Payment:', apiResponse.error);
            handleModalClose();
        }
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
        setSnackbarMsg('');
    };

    const emailNotification = async () => {
        const emailNotification = await sendNotificationApi(
            user.response.id,
            "Product Purchased. Check your email",
            user.response.id,
            "webPayment",
            newProductId
        );
        const notificationApiResult = await fetchNotificationsApi(user.response.id);
        setNotification([...notificationApiResult.data]);
        setFetchAgain(true);
        console.log(emailNotification.data)
    };

    useEffect(() => {
        if (productBuySuccess) {
            emailNotification();
        }
    }, [newProductId]);

    return (
        <>
            <Snackbar
                open={open}
                autoHideDuration={2000}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={handleClose}
                    severity={snackbarSeverity}
                >
                    {snackbarMsg}
                </MuiAlert>
            </Snackbar>
            <Card style={{ ...customCardStyle, width: '25.5625rem', }}>
                <CardContent style={contentStyle}>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'flex-start',
                    }}>
                        <div style={{ ...labelStyle, fontSize: isSmallMobile ? '1rem' : '1.375rem', }}>{BASE_PRICE}</div>
                        <div style={{ ...dollarStyle, marginTop: '1.44rem', marginLeft: 'auto' }}>{DOLLAR}</div>
                        <div style={{ ...priceStyle, fontSize: isSmallMobile ? '1.6rem' : '2rem', }}>{price}</div>
                    </div>

                    <div style={{ marginTop: isSmallMobile ? '1.3rem' : '1.81rem' }}>
                        <div style={lineStyle} ></div>
                    </div>

                    <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                        <DoneIcon style={{ ...tickStyle, marginTop: '1.59rem' }} />
                        <div style={{ ...bulletContentStyle, fontSize: isSmallMobile ? '0.65rem' : '0.8125rem' }}>
                            {LOREM_IPSUM_DOLOR_SIT}
                        </div>
                    </div>

                    <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                        <DoneIcon style={{ ...tickStyle, marginTop: '0.72rem' }} />
                        <div style={{ ...bulletContentStyle, fontSize: isSmallMobile ? '0.65rem' : '0.8125rem', marginTop: '0.5rem' }}>
                            {BORIS_NISI}
                        </div>
                    </div>

                    <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                        <DoneIcon style={{ ...tickStyle, marginTop: '0.72rem' }} />
                        <div style={{ ...bulletContentStyle, fontSize: isSmallMobile ? '0.65rem' : '0.8125rem', marginTop: '0.5rem' }}>
                            {UIS_AUTE}
                        </div>
                    </div>
                    {/* 
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div
                            style={{
                                ...checkBoxStyle,
                                marginTop: '1.75rem',
                                backgroundColor: extendSupport ? '#3F0065' : 'transparent',
                            }}
                            onClick={() => setExtendSupport(!extendSupport)}
                        >
                            {extendSupport && (
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="#FFFFFF"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    style={{ width: '70%', height: '70%' }}
                                >
                                    <path d="M1 12L8 19L23 4"></path>
                                </svg>
                            )}
                        </div>
                        <div style={{ ...bulletContentStyle, fontSize: isSmallMobile ? '0.65rem' : '0.875rem', fontWeight: '400', marginLeft: '0.94rem' }}>
                            {EXTEND_SUPPORT}<br />{MONTHS}
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', marginLeft: 'auto' }}>
                            <div style={{ ...newPriceDollar, marginTop: '-0.5rem', color: '#8A8A8A', marginRight: isSmallMobile ? '0.2rem' : '0' }}>{DOLLAR}</div>
                            <div style={{ ...oldPrice, marginRight: isSmallMobile ? '0.3rem' : '1rem', fontSize: isSmallMobile ? '0.8rem' : '1.0625rem', textDecoration: 'line-through' }}>{PRICE_TAG_OLD}</div>
                            <div style={{ ...newPriceDollar, marginTop: '-0.5rem' }}>{DOLLAR}</div>
                            <div style={{ ...newPrice, marginLeft: isSmallMobile ? '0.1rem' : '0.2rem', fontSize: isSmallMobile ? '0.8rem' : '1.0625rem', }}>{PRICE_TAG_NEW}</div>
                        </div>
                    </div> */}
                    <LoginBoxBtn style={{ width: '100%', marginTop: '2.56rem', justifyContent: 'center' }} onClick={handleTermsModalOpen} ><img style={{ marginRight: '1.09rem', marginBottom: '0.3rem' }} src={PurchaseProductImg} alt="" />{PURCHASE_PRODUCT}</LoginBoxBtn>
                    <div style={{ ...sellerInfo, fontSize: isSmallMobile ? '1rem' : '1.375rem', marginTop: '1.69rem' }}>
                        {SELLER_INFO}
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', flexDirection: isSmallMobile ? 'column' : 'inherit' }}>
                        <div style={{ marginTop: '1.62rem' }}>
                            <img style={imageStyle} src={profilePicture ? profilePicture : Avatar} alt='Seller Info' />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div style={{ ...Jamesandlaura, marginLeft: '0.94rem', marginTop: isSmallMobile ? '0.5rem' : '2.2rem', textAlign: isSmallMobile ? 'center' : 'inherit' }}>
                                {firstName} {lastName}
                            </div>

                            <div style={{ ...BusinessGrowth, marginLeft: '0.94rem', marginTop: '0.12rem', textAlign: isSmallMobile ? 'center' : 'inherit' }}>
                                {companyName ? companyName : BUSINESS_GROWTH}
                            </div>

                            <div style={{ ...BusniessGrowthSubtext, marginLeft: '0.94rem', marginTop: '0.31rem', textAlign: isSmallMobile ? 'center' : 'inherit' }}>
                                {bio && bio.length > 120 ? `${bio.substring(0, 120)}...` : bio || "Tech enthusiast turned seller! Offering cutting-edge gadgets and solutions to elevate your tech experience!"}
                            </div>
                        </div>
                    </div>
                    <div style={{ ...sellerInfo, fontSize: isSmallMobile ? '1rem' : '1.375rem', marginTop: '1.44rem' }}>
                        {MESSAGE}
                    </div>
                    <div style={{ marginTop: '1.25rem', }}>

                        <TextareaAutosize style={{
                            ...textAreaStyle, width: '100%', '&:focus': {
                                borderColor: '#3F0065',
                            },
                        }} placeholder={LEAVE_A_MSG} value={message} onChange={handleTextareaChange} onFocus={handleFocus} onBlur={handleBlur} />
                    </div>
                    <div style={{ justifyContent: 'center' }}>
                        <LoginBoxBtn onClick={accessChat} style={{ width: '100%', marginTop: '1.7rem', }}>
                            {sendingMsg ? <CircularProgress color='secondary' style={{ width: '27px', height: '25px' }} /> :
                                <>
                                    <img style={{ marginRight: '0.5rem' }} src={DropMessage} alt="" />{DROP_A_MSG}
                                </>
                            }
                        </LoginBoxBtn>
                    </div>
                </CardContent>
            </Card>
            <Modal
                isOpen={isModalOpen}
                onRequestClose={handleModalClose}
                contentLabel="Subscribe Modal"
                style={{
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        zIndex: 1000
                    },
                    content: {
                        width: isSmallMobile ? '95%' : '400px', // Adjust the width as per your preference
                        height: isSmallMobile ? 'max-content' : '350px',
                        margin: 'auto',
                        borderRadius: '0.75rem',
                        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                        padding: '1.5rem',
                        zIndex: 1001,
                        inset: isSmallMobile ? '0px' : '40px',
                        top: isSmallMobile ? '25%' : '',
                        left: isSmallMobile ? '2.5%' : ''
                    },
                }}
            >
                <img src={CardIcon} alt="" style={{
                    width: '3.5rem',
                    height: '3.5rem',
                    borderRadius: '0.5rem',
                    border: '8px solid var(--primary-50, #F9F5FF)',
                    background: 'var(--primary-100, #F4EBFF)',
                    marginBottom: '1.25rem',
                }} />
                <h2 style={{
                    color: '#46137C',
                    fontFamily: 'Poppins',
                    fontSize: '1.5rem',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    lineHeight: 'normal',
                    display: 'flex',

                }}>
                    Purchase Product
                </h2>
                <div style={enterCard}>Enter your card details</div>
                <CardElement
                    options={{
                        style: {
                            base: {
                                fontSize: '16px',
                                color: '#424770',
                                '::placeholder': {
                                    color: '#aab7c4',
                                },
                            },
                            invalid: {
                                color: '#9e2146',
                            },
                        },
                    }}
                />
                <div style={{ display: 'flex', marginTop: '1.2rem' }}>
                    <button
                        onClick={handleModalClose}
                        style={{
                            width: '50%',
                            borderRadius: '0.4375rem',
                            background: '#EDEDED',
                            color: '#8F8F8F',
                            fontFamily: 'Poppins',
                            fontSize: '0.8125rem',
                            fontStyle: 'normal',
                            fontWeight: 600,
                            lineHeight: 'normal',
                            marginTop: '3rem',
                            border: 'none',
                            display: 'block',
                            padding: '8px',
                            marginRight: '0.75rem'
                        }}
                    >
                        {CANCEL}
                    </button>
                    <button
                        onClick={handleBuyProduct}
                        style={{
                            width: '50%',
                            borderRadius: '0.4375rem',
                            background: '#3F0065',
                            color: '#FFF',
                            fontFamily: 'Poppins',
                            fontSize: '0.8125rem',
                            fontStyle: 'normal',
                            fontWeight: 600,
                            lineHeight: 'normal',
                            marginTop: '3rem',
                            border: 'none',
                            display: 'block',
                            padding: '8px',
                            transition: 'opacity 0.3s',
                            opacity: 1,
                        }}
                        onMouseOver={(e) => e.target.style.opacity = 0.8}
                        onMouseOut={(e) => e.target.style.opacity = 1}
                    >
                        {!loading ? 'Buy Now' : <CircularProgress color="secondary" style={{ width: '15px', height: '15px' }} />}
                    </button>
                </div>
            </Modal>

            <TermsConditionsModal
                isOpen={isTermsModalOpen}
                onClose={handleTermsModalClose}
                onAccept={handleModalOpen}
            />
        </>
    );
};

export default BasePriceCard;
