import NavBar from "../../components/Navbar";
import LandingPageInputBox from "../../components/LandingPage/LandingPageInputBox";
import TrendingProducts from "../../components/LandingPage/TrendingProducts";
import SalesForce from "../../components/LandingPage/SalesForce";
import Footer from '../../components/Footer';
import TopSection from "../../components/LandingPage/TopSection";
import { LandingPageItem } from '../../assets/styles/styles';
import FooterDivider from "../../components/FooterDivider";
import { useMediaQuery } from "react-responsive";
import { motion, useAnimation } from "framer-motion";
import { useState, useEffect } from "react";
import { CircularProgress } from "@mui/material";
import { fetchLandingProducts } from "../../Services/Products";
import { getSellers } from "../../Services/User";
import { Helmet } from "react-helmet";
import { HEADER_DATA } from "./constants";
import SpinnerComponent from "../../components/Spinner";
import { Container } from "@mui/material";
import ProductsFilters from "../../components/ViewAllProducts/ProductsFilters";
import { useNotification } from "../../Context/NotificationContext";

const LandingPage = () => {
  const isMobileTwo = useMediaQuery({ maxWidth: 600 });
  const isMobile = useMediaQuery({ minWidth: 360 });
  const [searchQuery, setSearchQuery] = useState('');
  const [isFetching, setIsFetching] = useState(true);
  const [filteredSellers, setFilteredSellers] = useState([]);
  const [products, setProducts] = useState([]);
  const { selected } = useNotification();

  const fetchSellersData = async (limit) => {
    try {
      const sellersData = await getSellers(limit);
      setFilteredSellers(sellersData);
      setIsFetching(false);
    } catch (error) {
      console.error('Error fetching sellers:', error);
      setIsFetching(false);
    }
  };

  const fetchProductsData = async (limit) => {
    try {
      const productsData = await fetchLandingProducts(limit, selected);
      setProducts(productsData);
      setIsFetching(false);
    } catch (error) {
      console.error('Error fetching products:', error);
      setIsFetching(false);
    }
  };

  useEffect(() => {
    const limit = isMobileTwo ? 3 : 8;

    // Only set isFetching to true if not changing the screen size
    if (!isMobile) {
      setIsFetching(true);
    }

    fetchProductsData(limit);
    fetchSellersData(limit);
  }, [isMobileTwo, selected]);

  const trendingProductsControls = useAnimation();

  const animateSection = async (sectionId, controls) => {
    const windowHeight = window.innerHeight;
    const scrollY = window.scrollY || window.pageYOffset;
    const section = document.getElementById(sectionId);

    if (section) {
      const sectionOffset = section.offsetTop;
      if (sectionId === 'trending-products-section' && windowHeight > 759) {
        await controls.start({ opacity: 1, y: 0 });
      }
      else {
        if (scrollY > sectionOffset - window.innerHeight / 2) {
          await controls.start({ opacity: 1, y: 0 });
        }
      }
    }
  };

  useEffect(() => {
    const animateTrendingProducts = async () => {
      await animateSection("trending-products-section", trendingProductsControls);
    };

    window.addEventListener("scroll", animateTrendingProducts);

    return () => window.removeEventListener("scroll", animateTrendingProducts);
  }, [trendingProductsControls]);

  const salesForceControls = useAnimation();

  useEffect(() => {
    const animateSalesForce = async () => {
      await animateSection("sales-force-section", salesForceControls);
    };

    window.addEventListener("scroll", animateSalesForce);

    return () => window.removeEventListener("scroll", animateSalesForce);
  }, [salesForceControls]);

  return (
    <>
      <Container maxWidth={false} sx={{ maxWidth: '1800px' }} disableGutters>
        <Helmet>
          <title>Cornucopia AI</title>
        </Helmet>
        {isMobile && <LandingPageItem />}
        <div style={{ marginBottom: '-200px' }}>
          <NavBar header_data={HEADER_DATA} header_button={{ label: 'Join the venture', navigation_url: '/buyerseller' }} mobile_nav_height={'16.5rem'} />
          <TopSection />
          {/* <LandingPageInputBox searchQuery={searchQuery} setSearchQuery={setSearchQuery} /> */}
          {isFetching ?
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
              {/* <CircularProgress color='secondary' style={{ width: '27px', height: '25px' }} /> */}
              <SpinnerComponent />
            </div>
            :
            <>
              {window.innerHeight > 760 ?
                <motion.div
                  style={{ marginTop: '6rem', opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.3 }}
                >
                  <>
                    <ProductsFilters  maxWidth="95%"/>
                    <TrendingProducts products={products} id="trending-products-section" />
                  </>
                </motion.div>

                :
                <motion.div
                  id="trending-products-section"
                  initial={{ opacity: 0, y: -30 }}
                  animate={trendingProductsControls}
                  transition={{ duration: 0.3 }}
                  style={{ marginTop: '6rem' }}
                >
                  <>
                    <ProductsFilters />
                    <TrendingProducts products={products} id="trending-products-section" />
                  </>
                </motion.div>
              }

              <motion.div
                id="sales-force-section"
                initial={{ opacity: 0, y: 30 }}
                animate={salesForceControls}
                transition={{ duration: 0.3 }}
              >
                <SalesForce filteredSellers={filteredSellers} />
              </motion.div>
            </>
          }
        </div>
      </Container>
      <FooterDivider />
      <Footer />
    </>
  )
};

export default LandingPage;
