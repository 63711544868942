import NavBar from "../../components/Navbar";
import ImageSlider from "./ImageSlider";
import { useState, useEffect } from "react";
import { FlexDiv } from "../../assets/styles/styles";
import { Typography, Grid } from "@mui/material";
import { useMediaQuery } from "react-responsive";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import GroupIcon from '../../assets/images/group.svg';
import Trolley from "../../assets/images/trolley.svg";
import BasePriceCard from "../../components/ProductDetailsBuyer/BasePriceCard";
import Discover from "../../assets/images/discover.png";
import FooterDivider from "../../components/FooterDivider";
import Footer from "../../components/Footer";
import DoneIcon from '@mui/icons-material/Done';
import ReviewCard from "../../components/ProductDetailsBuyer/ReviewCard";
import { useNavigate } from 'react-router-dom';
import VideoModal from "../../components/ProductDetailsBuyer/VideoModal";
import { BACK_TO_PRODUCT_LIST, DESCRIPTION, FEATURES, LIVE_PREVIEW, PERKS_AND_BENEFITS, PRICE, PRODUCT_DETAILS, REVIEWS_AND_RATING, PRODUCT_LOREM_DESCRIPTION } from "../../constants/text";
import { Elements } from "@stripe/react-stripe-js";
import { useParams } from "react-router-dom";
import RateAndReview from "../../components/PurchasedProductAuthUser/RateAndReview"
import CircularProgress from '@mui/material/CircularProgress';
import NoReview from "../../components/SellerDashboard/NoReviews";
import { getProductDetails, getReviews } from "../../Services/Products";
import CustomPagination from "../../components/CustomPagination";
import { Helmet } from "react-helmet";
import { Snackbar } from "@mui/material";
import MuiAlert from '@mui/material/Alert';
import DownloadModal from "./DownloadModal";
import { useNotification } from "../../Context/NotificationContext";
import { stripePromise, HEADER_DATA, BackDiv, LivePreview, FeaturesDiv, Images, BackToProduct, AddProduct, Heading, HeadingNum, FeaturesDivText, tickStyle, summary, TickText } from "./constants";
import { Container } from "@mui/material";

const BuyerProductDetails = () => {
    const [productDetails, setProductDetails] = useState(null);
    const [productFile, setProductFile] = useState(null);
    const isDesktop = useMediaQuery({ minWidth: 1170 });
    const isDesktopThree = useMediaQuery({ minWidth: 1050 });
    const [userHasReview, setUserHasReview] = useState(false);
    const isMobile = useMediaQuery({ maxWidth: 450 });
    const isDesktopTwo = useMediaQuery({ minWidth: 1280 });
    const isLaptop = useMediaQuery({ minWidth: 900 });
    const isTab = useMediaQuery({ minWidth: 640 });
    const [isModalOpen, setModalOpen] = useState(false);
    const navigate = useNavigate();
    const { id } = useParams();
    const userData = JSON.parse(localStorage.getItem('Cornucopia-user'));
    const [loading, setLoading] = useState(true);
    const [productBought, setProductBought] = useState(false);
    const [reviews, setReviews] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState();
    const [updatedReview, setUpdatedReview] = useState(false);
    const [open, setOpen] = useState(false);
    const [snackbarMsg, setSnackbarMsg] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [showDownloadModal, setShowDownloadModal] = useState(false);
    const [productType, setProductType] = useState();
    const { newProductId } = useNotification();

    const handleBackClick = () => {
        navigate('/products');
    };

    const fetchProductDetails = async () => {
        try {
            const productDetails = await getProductDetails(id);
            if (productDetails.response) {
                if (productDetails.response.productFiles) {
                    setProductFile(productDetails.response.productFiles)
                }
                if (productDetails.response.type) {
                    setProductType(productDetails.response.type)
                }
                setProductDetails(productDetails.response);
                setLoading(false);
            }
            else {
                setSnackbarMsg(productDetails.message);
                setSnackbarSeverity("error");
                setOpen(true);
                setLoading(true);
            }
        } catch (error) {
            console.error('Error fetching product details:', error);
        }
    };

    const fetchReviews = async () => {
        try {
            let limit = NumOfReviews();
            const reviewsResponse = await getReviews(id, currentPage, undefined, undefined, setCurrentPage, limit);
            setReviews(reviewsResponse.reviews);
            setTotalPages(reviewsResponse.totalPages);
        } catch (error) {
            console.error('Error fetching reviews:', error);
        }
    };

    const handleCloseDownloadModal = () => {
        setShowDownloadModal(false);
    };
    const NumOfReviews = () => {
        if (!isTab) {
            return '1';
        } else if (!isLaptop) {
            return '2';
        } else {
            return '3';
        }
    };

    useEffect(() => {
        if (!productBought)
            setLoading(true);
        fetchProductDetails();
        if (productBought && productDetails?.productFiles) {
            setShowDownloadModal(true);
        }
    }, [productBought]);

    useEffect(() => {
        fetchReviews();
        setUpdatedReview(false);
    }, [currentPage, userHasReview, updatedReview, isTab, isLaptop]);

    const openModal = () => {
        setModalOpen(true);
    };
    const closeModal = () => {
        setModalOpen(false);
    };
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
        setSnackbarMsg('');
        navigate('/my-products')
    };

    function chunkArray(arr, chunkSize) {
        const chunkedArray = [];
        for (let i = 0; i < arr.length; i += chunkSize) {
            chunkedArray.push(arr.slice(i, i + chunkSize));
        }
        return chunkedArray;
    }
    if (productDetails) {
    }
    return (
        <>
            <Container maxWidth={false} sx={{ maxWidth: '1800px' }} disableGutters>

                <Helmet>
                    <title>Cornucopia AI | Buyer-Product Details</title>
                </Helmet>
                <Snackbar
                    open={open}
                    autoHideDuration={2000}
                    onClose={handleClose}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                >
                    <MuiAlert
                        elevation={6}
                        variant="filled"
                        onClose={handleClose}
                        severity={snackbarSeverity}
                    >
                        {snackbarMsg}
                    </MuiAlert>
                </Snackbar>
                {userData &&
                    <DownloadModal open={showDownloadModal} onClose={handleCloseDownloadModal} downloadLink={productFile} web={productType === 'web' ? true : false} heading={productType === 'web' ? 'Purchase Successful' : 'Download Link'} subHeading={productType === 'web' ? 'Please check your email for purchased website onboarding. If you dont receive email in 1 minute click Resend Email' : 'Click the button below to download the file:'} buttonText={productType === 'web' ? 'Cancel' : 'Download'} productId={newProductId} />
                }
                <NavBar header_data={HEADER_DATA} header_button={{ label: 'Join the venture', navigation_url: '/buyerseller' }} mobile_nav_height={'260px'} />
                {loading ?
                    <FlexDiv style={{ marginBottom: '-200px', justifyContent: 'center', alignItems: 'center', marginTop: '100px', height: '90vh' }}>
                        <CircularProgress color="secondary" style={{ width: '27px', height: '25px' }} />
                    </FlexDiv>
                    :
                    <div style={{ marginLeft: isDesktop ? '4.37rem' : '1rem', marginTop: '3.25rem', marginRight: isDesktop ? '3rem' : '1rem', marginBottom: '-200px' }}>
                        <FlexDiv style={{ justifyContent: 'space-between' }}>
                            <FlexDiv style={{ gap: '0.6rem', paddingTop: '0.5rem' }}>
                                <button style={{ display: 'contents' }} onClick={handleBackClick}>
                                    <BackDiv>
                                        <KeyboardBackspaceIcon sx={{ color: '#777777' }} />
                                    </BackDiv>
                                </button>
                                {!isMobile && <div>
                                    <Typography sx={BackToProduct}>{BACK_TO_PRODUCT_LIST}</Typography>
                                    <Typography sx={AddProduct}>{PRODUCT_DETAILS}</Typography>
                                </div>
                                }
                            </FlexDiv>
                            {productDetails?.demoVideo.includes('https://cornucopia-uploads') &&
                                <>
                                    <button style={{ display: 'contents' }} onClick={openModal}>
                                        <LivePreview style={{ marginTop: '0.5rem', paddingTop: '0.75rem' }}>
                                            <img src={GroupIcon} alt="" style={{ width: '0.90313rem', height: '1.0625rem', margin: '0rem 0.7rem 0rem 2.8rem' }} />
                                            {LIVE_PREVIEW}
                                        </LivePreview>
                                    </button>
                                    <VideoModal open={isModalOpen} onClose={closeModal} videoURL={productDetails.demoVideo} />
                                </>
                            }
                        </FlexDiv>
                        <FlexDiv style={{ marginTop: '1.5rem', justifyContent: 'space-between' }}>
                            <div>
                                <FlexDiv style={{ gap: isDesktopThree ? '1.3rem' : '', flexDirection: isDesktopThree ? 'row' : 'column' }}>
                                    {productDetails ? (
                                        <>
                                            <Typography sx={Heading}>{productDetails.name}</Typography>
                                            <Typography sx={HeadingNum} style={{ marginTop: '0.3rem' }}>{`(${productDetails.buyers.length})`}</Typography>
                                        </>
                                    )
                                        :
                                        <></>
                                    }
                                </FlexDiv>
                                {productDetails && chunkArray(productDetails.category, 3).map((filteredArray) => (
                                    <FlexDiv key={`key-${filteredArray[0]}`} style={{ gap: '0.25rem', marginTop: '0.8rem' }}>
                                        {filteredArray.map((data) => (
                                            <FeaturesDiv key={data} style={{ padding: '0.2rem 0rem 0.2rem 0.5rem', gap: '0.2rem' }}>
                                                <img src={Trolley} alt="" style={{ width: '0.6875rem', height: '0.625rem', marginTop: '0.2rem' }} />
                                                <Typography sx={FeaturesDivText} style={{ marginRight: '0.3rem' }}>{data}</Typography>
                                            </FeaturesDiv>
                                        ))}
                                    </FlexDiv>
                                ))}
                                <Typography sx={summary} style={{ marginTop: '1rem', maxWidth: '60rem', marginRight: '0.5rem' }}>{productDetails.description}</Typography>
                                {productDetails && isDesktopThree && (
                                    <img
                                        src={productDetails.images[0]}
                                        alt=""
                                        style={{
                                            borderRadius: '1.125rem',
                                            width: isDesktop ? "39.3125rem" : '35rem',
                                            height: "28.75rem",
                                            marginTop: '1.5rem',
                                            boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2)'
                                        }}
                                    />
                                )}
                                {!isDesktopThree && productDetails && (
                                    <div style={{ width: isLaptop ? '50vw' : '90vw', marginTop: '1rem' }}>
                                        <ImageSlider images={productDetails.images} />
                                    </div>
                                )}

                                {productDetails && isDesktopThree &&
                                    <Grid container gap={isDesktopTwo ? '0rem' : isMobile ? '1rem' : '3rem'} style={{ marginTop: '1.6rem' }}>
                                        {!isDesktopThree && productDetails && (
                                            <Grid item lg={4} md={4} sm={isTab ? 3 : 5} xs={isMobile ? 10 : 5}>
                                                <Images src={productDetails.images[0] || Discover} alt="" />
                                            </Grid>
                                        )}
                                        {productDetails.images[1] &&
                                            < Grid item lg={4} md={isDesktopThree ? 3 : 4} sm={isLaptop ? 4 : isTab ? 3 : 5} xs={isDesktopThree ? 3 : isMobile ? 10 : 5}>
                                                <Images src={productDetails.images[1]} alt="" />
                                            </Grid>
                                        }
                                        {productDetails.images[2] &&
                                            <Grid item lg={4} md={isDesktopThree ? 3 : 4} sm={isLaptop ? 4 : isTab ? 3 : 5} xs={isDesktopThree ? 3 : isMobile ? 10 : 5}>
                                                <Images src={productDetails.images[2]} alt="" />
                                            </Grid>
                                        }
                                        {productDetails.images[3] &&
                                            <Grid item lg={4} md={isDesktopThree ? 3 : 4} sm={isLaptop ? 4 : isTab ? 3 : 5} xs={isDesktopThree ? 3 : isMobile ? 10 : 5}>
                                                <Images src={productDetails.images[3]} alt="" />
                                            </Grid>
                                        }
                                    </Grid>
                                }
                            </div>
                            {isLaptop &&
                                <div>
                                    {productDetails && userData ? (
                                        <>
                                            {productDetails.buyers && productDetails.buyers.includes(userData.response.id) ? (
                                                // User is in buyers array, display RateAndReview component
                                                <RateAndReview sellerId={productDetails.user.id} productID={productDetails.id} productCost={productDetails.price} userHasReview={userHasReview} setUserHasReview={setUserHasReview} firstName={productDetails.user.firstName} lastName={productDetails.user.lastName} profilePicture={productDetails.user.displayPicture ? productDetails.user.displayPicture : null} companyName={productDetails.user.company ? productDetails.user.company.companyName : null} bio={productDetails.user.bio ? productDetails.user.bio : null} setUpdatedReview={setUpdatedReview} />
                                            ) : (
                                                // User is not in buyers array, display BasePriceCard component
                                                <Elements stripe={stripePromise}>
                                                    <BasePriceCard productName={productDetails.name} price={productDetails.price} sellerId={productDetails.user.id} productId={productDetails.id} setProductBought={setProductBought} firstName={productDetails.user.firstName} lastName={productDetails.user.lastName} profilePicture={productDetails.user.displayPicture ? productDetails.user.displayPicture : null} companyName={productDetails.user.company ? productDetails.user.company.companyName : null} bio={productDetails.user.bio ? productDetails.user.bio : null} />
                                                </Elements>
                                            )}
                                        </>
                                    ) : (
                                        productDetails ? (
                                            <Elements stripe={stripePromise}>
                                                <BasePriceCard productName={productDetails.name} price={productDetails.price} firstName={productDetails.user.firstName} lastName={productDetails.user.lastName} profilePicture={productDetails.user.displayPicture ? productDetails.user.displayPicture : null} companyName={productDetails.user.company ? productDetails.user.company.companyName : null} bio={productDetails.user.bio ? productDetails.user.bio : null} />
                                            </Elements>
                                        ) : null
                                    )}
                                </div>
                            }
                        </FlexDiv >
                        <div>
                            {productDetails && (
                                <>
                                    <Typography sx={Heading} style={{ fontSize: '1.25rem', marginTop: '2rem' }}>{DESCRIPTION}</Typography>
                                    <Typography sx={summary} style={{ fontSize: '1.0625rem' }}>{productDetails.description}</Typography>
                                </>
                            )}
                        </div>
                        <FlexDiv style={{ gap: isTab ? '5rem' : '1rem', flexDirection: isTab ? 'row' : 'column' }}>
                            {productDetails.features.length != 0 &&
                                <div style={{ width: isTab ? '50%' : '' }} >
                                    <Typography sx={Heading} style={{ fontSize: '1.25rem', marginTop: '2rem' }}>{FEATURES}</Typography>
                                    {productDetails.features.length != 0 ? (
                                        productDetails.features.map((feature, index) => (
                                            <FlexDiv key={`feature-${index}`} style={{ marginTop: '0.5rem', gap: '0.5rem', }}>
                                                <DoneIcon sx={tickStyle} style={{ marginTop: '0.3rem' }} />
                                                <Typography sx={summary} style={{ maxWidth: '36.25rem' }}>{feature}</Typography>
                                            </FlexDiv>
                                        ))
                                    ) : (
                                        TickText.map((data, index) => (
                                            <FlexDiv key={`tick-${index}`} style={{ marginTop: '0.5rem', gap: '0.5rem' }}>
                                                <DoneIcon sx={tickStyle} style={{ marginTop: '0.3rem' }} />
                                                <Typography sx={summary} style={{ maxWidth: '36.25rem' }}>{data}</Typography>
                                            </FlexDiv>
                                        ))
                                    )}

                                </div>
                            }
                            {productDetails.perks.length != 0 &&
                                <div style={{ width: isTab ? '50%' : '' }}>
                                    <Typography sx={Heading} style={{ fontSize: '1.25rem', marginTop: '2rem' }}>{PERKS_AND_BENEFITS}</Typography>
                                    {productDetails.perks.length != 0 ? (
                                        productDetails.perks.map((perk, index) => (
                                            <FlexDiv key={`perk-${index}`} style={{ marginTop: '0.5rem', gap: '0.5rem', }}>
                                                <DoneIcon sx={tickStyle} style={{ marginTop: '0.3rem' }} />
                                                <Typography sx={summary} style={{ maxWidth: '36.25rem' }}>{perk}</Typography>
                                            </FlexDiv>
                                        ))
                                    ) : (
                                        TickText.map((data, index) => (
                                            <FlexDiv key={`tick-${index}`} style={{ marginTop: '0.5rem', gap: '0.5rem' }}>
                                                <DoneIcon sx={tickStyle} style={{ marginTop: '0.3rem' }} />
                                                <Typography sx={summary} style={{ maxWidth: '36.25rem' }}>{data}</Typography>
                                            </FlexDiv>
                                        ))
                                    )}
                                </div>
                            }
                        </FlexDiv>
                        {
                            !isLaptop &&
                            <>
                                <Typography sx={Heading} style={{ fontSize: '1.25rem', marginTop: '2rem' }}>{PRICE}</Typography>
                                <FlexDiv style={{ justifyContent: 'center', marginTop: '1rem' }}>
                                    {productDetails && userData ? (
                                        // Both user data and product details are available
                                        <>
                                            {productDetails.buyers && productDetails.buyers.includes(userData.response.id) ? (
                                                // User is in buyers array, display RateAndReview component
                                                <RateAndReview sellerId={productDetails.user.id} productID={productDetails.id} productCost={productDetails.price} userHasReview={userHasReview} setUserHasReview={setUserHasReview} firstName={productDetails.user.firstName} lastName={productDetails.user.lastName} profilePicture={productDetails.user.displayPicture ? productDetails.user.displayPicture : null} companyName={productDetails.user.company ? productDetails.user.company.companyName : null} bio={productDetails.user.bio ? productDetails.user.bio : null} setUpdatedReview={setUpdatedReview} />
                                            ) : (
                                                // User is not in buyers array, display BasePriceCard component
                                                <Elements stripe={stripePromise}>
                                                    <BasePriceCard productName={productDetails.name} price={productDetails.price} sellerId={productDetails.user.id} productId={productDetails.id} setProductBought={setProductBought} firstName={productDetails.user.firstName} lastName={productDetails.user.lastName} profilePicture={productDetails.user.displayPicture ? productDetails.user.displayPicture : null} companyName={productDetails.user.company ? productDetails.user.company.companyName : null} bio={productDetails.user.bio ? productDetails.user.bio : null} />
                                                </Elements>
                                            )}
                                        </>
                                    ) : (
                                        // Either user data or product details (or both) are not available, display BasePriceCard component
                                        productDetails ? (
                                            // productDetails is not null, proceed with destructuring
                                            <Elements stripe={stripePromise}>
                                                <BasePriceCard productName={productDetails.name} price={productDetails.price} firstName={productDetails.user.firstName} lastName={productDetails.user.lastName} profilePicture={productDetails.user.displayPicture ? productDetails.user.displayPicture : null} companyName={productDetails.user.company ? productDetails.user.company.companyName : null} bio={productDetails.user.bio ? productDetails.user.bio : null} />
                                            </Elements>
                                        ) : null
                                    )}
                                </FlexDiv>
                            </>
                        }
                        <Typography sx={Heading} style={{ fontSize: '1.5625rem', marginTop: '1.5rem' }}>{REVIEWS_AND_RATING}</Typography>
                        {
                            reviews.length > 1 ? (
                                <FlexDiv style={{ justifyContent: 'center', padding: '2rem 0rem 0rem 0rem', gap: isDesktopThree ? '2.5rem' : '1rem' }}>
                                    {reviews.slice(0, -1).map((review) => (
                                        <div key={review._id} style={{ marginTop: '1.5rem' }}>
                                            {review.user && review.user.fullName && (
                                                <ReviewCard
                                                    rating={review.rating}
                                                    comment={review.comment}
                                                    fullName={review.user.fullName}
                                                    date={review.createdAt}
                                                />
                                            )}
                                        </div>
                                    ))}
                                </FlexDiv>
                            ) : (
                                <div style={{ marginTop: '1rem' }}>
                                    <NoReview />
                                </div>
                            )
                        }
                        {
                            reviews.length > 1 &&
                            <FlexDiv style={{ justifyContent: 'center', marginTop: '2rem' }}>
                                <CustomPagination page={currentPage}
                                    onChange={(event, newPage) => setCurrentPage(newPage)} count={totalPages} />
                            </FlexDiv>
                        }
                    </div >
                }
            </Container>
            <FooterDivider />
            <Footer />
        </>
    )
}
export default BuyerProductDetails;