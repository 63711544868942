import React, { useState } from 'react';
import Modal from 'react-modal';
import { FlexDiv } from '../../assets/styles/styles';
import WebsiteImg from '../../assets/images/website.svg';
import WebsiteChecked from '../../assets/images/website-checked.svg';
import DesktopSoftware from '../../assets/images/desktop-software.svg';
import DesktopChecked from '../../assets/images/desktop-checked.svg';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

Modal.setAppElement('#root');

const ProductTypeModal = ({ isOpen, onClose }) => {
    const [selectedType, setSelectedType] = useState(null);
    const isSmallScreen = useMediaQuery({ maxWidth: 600 });
    const navigate = useNavigate(); // Initialize navigate function

    const handleSelectType = (type) => {
        setSelectedType(type);
    };

    const handleCloseModal = () => {
        setSelectedType(null);
        onClose();
    };

    const handleContinue = () => {
        // Check if a product type is selected before continuing
        if (selectedType) {
            // Navigate to the desired route and pass the selected type
            navigate(`/seller/products/add/${selectedType}`);
        } else {
            // Show an alert or perform any other action to indicate that a type must be selected
            alert('Please select a product type before continuing.');
        }
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={handleCloseModal}
            style={{
                content: {
                    margin: 'auto',
                    height: "fit-content",
                    display: 'flex',
                    width: isSmallScreen ? '85%' : '30rem',
                    inset: '30px',
                    padding: '1.5rem',
                    flexDirection: 'column',
                    borderRadius: '0.75rem',
                    background: 'var(--White, #FFF)',
                    boxShadow: '0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08)',
                },
                overlay: {
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    zIndex: 100
                },
            }}
        >
            <FlexDiv style={{ flexDirection: 'column', gap: '0.5rem' }}>
                <h2
                    style={{
                        color: '#46137C',
                        fontFamily: 'Poppins',
                        fontSize: isSmallScreen ? '1.25rem' : '1.5rem',
                        fontStyle: 'normal',
                        fontWeight: 600,
                        lineHeight: 'normal',
                        margin: 0,
                    }}
                >
                    Select product type
                </h2>
            </FlexDiv>

            <FlexDiv style={{ marginTop: '1.5rem', flexDirection: 'column', gap: '1.3rem', width: '100%', marginBottom: '2rem' }}>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        background: selectedType === 'website' ? '#6A007C' : '#fff',
                        height: isSmallScreen ? '3.8125rem' : '6.8125rem',
                        borderRadius: '0.6875rem',
                        paddingLeft: '1.63rem',
                        border: selectedType === 'website' ? 'none' : '1px solid #9D40B1',
                        cursor: 'pointer',
                    }}
                    onClick={() => handleSelectType('website')}
                >
                    <img src={selectedType === 'website' ? WebsiteImg : WebsiteChecked} alt="Website" style={{ width: isSmallScreen ? '1.5rem' : '', height: isSmallScreen ? '1.5rem' : '' }} />
                    <span
                        style={{
                            color: selectedType === 'website' ? '#FFF' : '#9D40B1',
                            fontFamily: 'Poppins',
                            fontSize: isSmallScreen ? '1rem' : '1.375rem',
                            fontStyle: 'normal',
                            fontWeight: 500,
                            lineHeight: 'normal',
                            marginLeft: '0.5rem',
                        }}
                    >
                        Website
                    </span>
                </div>

                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        background: selectedType === 'desktop' ? '#6A007C' : '#fff',
                        height: isSmallScreen ? '3.8125rem' : '6.8125rem',
                        borderRadius: '0.6875rem',
                        paddingLeft: '1.63rem',
                        border: selectedType === 'desktop' ? 'none' : '1px solid #9D40B1',
                        cursor: 'pointer',
                    }}
                    onClick={() => handleSelectType('desktop')}
                >
                    <img src={selectedType === 'desktop' ? DesktopChecked : DesktopSoftware} alt="Desktop Software" style={{ width: isSmallScreen ? '1.5rem' : '', height: isSmallScreen ? '1.5rem' : '' }} />
                    <span
                        style={{
                            color: selectedType === 'desktop' ? '#FFF' : '#9D40B1',
                            fontFamily: 'Poppins',
                            fontSize: isSmallScreen ? '1rem' : '1.375rem',
                            fontStyle: 'normal',
                            fontWeight: 500,
                            lineHeight: 'normal',
                            marginLeft: '0.5rem',
                        }}
                    >
                        Desktop Software
                    </span>
                </div>
            </FlexDiv>
            <FlexDiv style={{ justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                <button
                    style={{
                        height: isSmallScreen ? '2.6875rem' : '3.6875rem',
                        flexShrink: 0,
                        borderRadius: '2.125rem',
                        border: 'none',
                        background: '#6A007C',
                        width: '60%',
                        color: '#FFF',
                        fontFamily: 'Poppins',
                        fontSize: isSmallScreen ? '0.875rem' : '1.25rem',
                        fontStyle: 'normal',
                        fontWeight: 500,
                        lineHeight: 'normal',
                        textAlign: 'center',
                    }}
                    onClick={handleContinue} // Call handleContinue function on button click
                >
                    Continue
                </button>
            </FlexDiv>
        </Modal>
    );
};

export default ProductTypeModal;
