import styled from "@emotion/styled";

export const HEADER_DATA = [
    {
        id: '1',
        name: 'Home',
        margin_right: '2rem',
        navigation_url: '/'
    },
    {
        id: '2',
        name: 'Products',
        margin_right: '2rem',
        navigation_url: '/products'
    },
    {
        id: '3',
        name: 'Sellers',
        margin_right: '2rem',
        navigation_url: '/sellers'
    },
    {
        id: '4',
        name: 'Login',
        margin_right: '0rem',
        navigation_url: '/login'
    },
];
export const HeaderText = styled.div`
    color: #6D0085;
    font-family: Poppins;
    font-size: 3rem;
    font-weight: 500;
    margin-top: 1rem;
`;
export const TokenExpired = (tokenDate) => {
    const convertedTime = new Date(tokenDate);
    const currentTime = new Date();
    if (currentTime >= convertedTime) {
        return true;
    }
    return false;
}
